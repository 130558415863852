<template>
    <div class="fragment">
        <el-alert class="alert" title="碎片数据是分片上传过程中产生的碎片文件，碎片管理提供释放数据功能，释放后，碎片文件所占用空间可恢复使用。" type="warning"
                  style="margin: 10px 0 0;"
                  show-icon
                  :closable="false"></el-alert>
        <div class="file-page">
            <div class="file-opts">
                <div>
                    <OptButton size="small" @click="handleAbortList" v-if="selectedRow && selectedRow.length > 0">批量释放</OptButton>
                    <el-tooltip v-else content="请选择碎片文件" placement="top" >
                        <span class="opt-button">
                          <el-button  size="small" disabled>批量释放</el-button>
                        </span>
                    </el-tooltip>
                </div>
                <div style="display: flex;">
                    <el-input
                        placeholder="按文件名前缀匹配"
                        v-model.trim="keyword"
                        style="width: 200px;"
                        @keyup.enter.native="searchFile"
                        class="input-with-select"
                        @input="handleSearchInput"
                    >
                        <el-button slot="append" icon="el-icon-search" style="font-size: 16px;"
                                   @click="searchFile" ></el-button>
                    </el-input>

                    <OptButton size="small" icon="el-icon-refresh-right" style="width: 32px;height: 30px;margin-left: 10px;font-size: 16px;padding: 0;"
                               @click="getAllData()">
                    </OptButton>
                </div>
            </div>

            <el-table
                :data="tableData"
                v-loading="loadingData"
                @selection-change="handleSelectionChange"
                style="width: 100%">
              <el-table-column type="selection"
                               width="45px"></el-table-column>
                <el-table-column prop="key" label="文件名" show-overflow-tooltip>
                    <FileName slot-scope="{ row }"
                              :filename="row.key"
                              />
                </el-table-column>
                <el-table-column prop="parts" label="碎片数量" width="80"></el-table-column>
                <el-table-column prop="sizeStr" label="碎片大小" width="80"></el-table-column>
                <el-table-column prop="initiated" label="创建时间" width="200"></el-table-column>
                <el-table-column prop="key" label="操作" width="80">
                    <template slot-scope="{$index, row}">
                        <div class="file-table-action-list">
                            <el-button type="text" @click="handleAbortItem(row)" style="margin-left: -6px;">释放</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <Pager

                v-show="tableData && tableData.length > 0"
                class="bs-pager" v-model="pager" :sizes="[5, 10, 20]" @currentChange="handleCurrentChange"
                @sizeChange="handleSizeChange"></Pager>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: "fragment",
    props: {
        bucket: {
            type: String,
            default: ''
        },
        regionId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            keyword: '',
            totalList: [],
            tableData: [],
            loadingData: false,
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            selectedRow: []
        }
    },
    created() {
        this.getAllData();
    },
    methods: {
        handleSearchInput (val) {
            this.searchFileTip = false
            let append = document.querySelector('.search-input .el-input-group__append')
            if (val) {
                if (append) {
                    append.classList.add('focus')
                }
            } else {
                if (append) {
                    append.classList.remove('focus')
                }
            }
        },
        searchFile () {
            this.getAllData();
        },
        handleAbortItem (row) {
            let data = {
                bucketName: this.bucket,
                key: row.key,
                uploadId: row.uploadId
            }
            this.handleAbort([data])
        },
        handleSelectionChange(e) {
            this.selectedRow = [...e];
        },
        handleAbortList () {
            const {selectedRow, bucket} = this;
            let data = selectedRow.map(i => ({
                bucketName: bucket,
                key: i.key,
                uploadId: i.uploadId,
            }))
            this.handleAbort(data)
        },
        async handleAbort (multipartList) {
            try {
                await this.$confirm("确认释放该碎片文件？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                })
                this.$postJson('/file/multi/abort', {multipartList, regionId: this.regionId})
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success('已释放')
                            this.pager.current = 1
                            this.getAllData();
                            this.$emit('complete-abort')
                        }
                    })
            } catch (e) {

            }

        },
        setTableList () {
            // 3 5
            const {pager: {current, size}, totalList} = this;
            let start = (current - 1) * size,
                end = current * size;
            if (end > totalList.length) {
                end = totalList.length
            }
            this.tableData = totalList.slice(start, end)
            this.getDetail();
        },
        getDetail () {
            const {tableData, bucket} = this;
            if (!tableData || tableData.length === 0) {
                return
            }
            let multipartList = tableData.map(i => ({
                bucketName: bucket,
                key: i.key,
                uploadId: i.uploadId,
            }))
            this.$postJson('/file/multi/part/list', {multipartList, regionId: this.regionId})
            .then(res => {
                if (res.code === '200') {
                    // key parts sizeStr
                    let o = {}
                    res.data.map(i => {
                        o[i.key] = i
                    })
                    for (let i = 0; i < tableData.length; i++) {
                        let item = o[tableData[i].key]
                        this.$set(this.tableData[i], 'parts', item.parts.length)
                        this.$set(this.tableData[i], 'sizeStr', item.sizeStr)
                    }
                }
            })

        },
        getAllData () {
            const {keyword} = this
            this.$postJson('/file/multi/list', {bucketName: this.bucket, prefix: keyword, regionId: this.regionId})
            .then(res => {
                if (res.code === '200') {
                    this.totalList = (res.data || []).map(i => ({...i, initiated: moment(i.initiated).format('YYYY-MM-DD HH:mm:ss')}))
                    this.pager.total = this.totalList.length || 0
                    this.setTableList()
                }
            })
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.setTableList();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.setTableList();
        },
    }
}
</script>

<style scoped lang="scss">
.fragment {
    padding: 20px;
}

.file-page {
    //隐藏dialog header
    ::v-deep.changeConfirm {
        .el-dialog__header {
            display: none;
        }
    }

    .el-table {
        color: #333333;
    }
}

.file-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}
</style>