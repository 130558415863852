<template>
    <div class="file-fault" style="padding: 20px;">
        <div class="title">
            <span>版本控制</span>
            <span class="content setup" v-if="(accountType === 'main' || bucketAuth === 'READ_AND_WRITE') && !showForm" @click="showForm=true">
                <i class="icon iconfont iconedit"></i>
            </span>
            <span v-else class="content setup disabled">
                <i class="icon iconfont iconedit"></i>
            </span>
        </div>
        <div class="status title" v-show="!showForm">
            <span style="color: #999;">当前状态</span>
            <span class="content" style="color: #666;">{{ {Off: '关闭', Enabled: '开启', Suspended: '已暂停'}[status] }}</span>
        </div>
        <el-form style="width: 800px;" :model="form" label-width="80px" v-show="showForm" label-position="left">
            <el-form-item label="当前状态" style="color: #999;" >
                <el-switch
                    v-model="form.status"
                    @change="switchChange"
                    active-text=""
                    :active-color="IS_SSC ? '#ff8700' : '#337DFF'"
                    inactive-color="#f2f2f2">
                </el-switch>
                <div class="tips">注：一旦开通版本控制后，将会保留源对象成为历史版本，当恢复历史版本时会占用存储空间；开启后，深度归档类型文件可能出现不兼容情况。</div>
            </el-form-item>
            <div>
                <el-button type="" size="small" @click="handleCancel">取消</el-button>
                <el-button type="primary" size="small" @click="handleSubmit">保存</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "file-fault",
    props: {
        bucket: {
            type: String,
            default: ''
        },
        regionId: {
            type: String,
            default: ''
        },
        bucketAuth: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    data() {
        return {
            showForm: false,
            form: {
                /**
                 不受版本控制（默认） Off
                 已启用版本控制 Enabled
                 已暂停版本控制 Suspended
                 */
                status: false
            },
            status: 'Off'
        }
    },

    created() {
        this.getInfo();
    },
    methods: {
        show () {
            this.getInfo();
        },
        getInfo() {
            let loading = this.$loading({target: '.file-fault'})
            this.$postJson('/bucket/version/get?bucketName=' + this.bucket + '&regionId=' + this.regionId )
                .then(res => {
                    if (res.code === '200') {
                        this.form.status = res.data.status === 'Enabled'
                        this.status = res.data.status;
                        this.$emit('version-change', this.status)
                        this.showForm = false
                    }
                    loading.close()

                })
                .catch(() => loading.close())
        },
        handleSubmit() {
            if (this.status === 'Off' && !this.form.status) {
                return
            }
            let loading = this.$loading({target: '.file-fault'})
            this.$postJson('/bucket/version/put?bucketName=' + this.bucket + '&enable=' + this.form.status + '&regionId=' + this.regionId)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success(`${this.form.status ? '开启' : '暂停'}版本控制成功`)
                        this.getInfo();
                    }
                    loading.close()
                })
                .catch(() => loading.close())
        },
        handleCancel() {
            this.showForm = false;
            this.form.status = this.status === 'Enabled'
        },
        switchChange(val) {
            let title = '', tips = '';
            if (val) {
                title = '确定开启版本控制？'
                tips = '开启版本控制会保留源对象成为历史版本，开启后，将无法返回到未开启状态，但可以暂停版本控制，后续上传同名文件不会产生多个版本'
            } else {
                title = '确定暂停版本控制？'
                tips = '暂停版本控制功能后，将仍保留历史版本且暂停支持桶内创建新版本。'
            }

            this.$confirm(`
                <b>${title}</b><br/>
                <p style="color:#999;">${tips}</p>
            `, {
                dangerouslyUseHTMLString: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: 'warning',
                closeOnClickModal: false,
                showClose:false
            })
                .then(res => {
                })
                .catch(res => {
                    this.form.status = !this.form.status
                })
        },
    }
}
</script>

<style scoped lang="scss">

.title {
    text-align: left;
    line-height: 35px;
    > span {
        font-size: 12px;
        color: #031129;
        letter-spacing: 0;
    }
    >span.content {
        font-size: 12px;
        color: #337DFF;
        letter-spacing: 0;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
    }
    span.setup {
        background-size: 14px;
    }
    span.setup.disabled {
        cursor: not-allowed;
        color: #999;
    }
}
.status {

}
.tips {
    font-size: 12px;
    color: #BBBBBB;
    letter-spacing: 0;
    line-height: 20px;
}
</style>