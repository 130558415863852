<template>
    <div class="bucket-home">
        <div class="section1 section">
            <div class="title">基础统计数据</div>
            <el-row class="bucket-home-info">
                <el-col :span="8">
                    <div class="info-item" style="border: none;">
                        <div class="info-label">下载次数</div>
                        <div class="info-value  el-tooltip">
                            <span class="number">{{ data.downOps }}</span> 次
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="info-item">
                        <div class="info-label">存储容量</div>
                        <div class="info-value  el-tooltip">
                            <span class="number">{{ data.sizeStr && data.sizeStr.split(' ')[0] }}</span>
                            {{ data.sizeStr && data.sizeStr.split(' ')[1] }}
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="info-item" style="border: none;">
                        <div class="info-label">外网流出流量</div>
                        <div class="info-value  el-tooltip">
                            <span class="number">{{ data.downSizeStr && data.downSizeStr.split(' ')[0] }}</span>
                            {{ data.downSizeStr && data.downSizeStr.split(' ')[1] }}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="section2 section">
            <div class="title">基本信息</div>
            <el-row class="bucket-home-info">
                <el-col :span="8">
                    <div class="info-item">
                        <div class="info-label">桶名</div>
                        <div class="info-value ellipsis el-tooltip">{{ data.name }}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-label">读写权限</div>
                        <div class="info-value">{{ data.bucketAcl }}
                            <span v-if="accountType === 'main' || bucketAuth === 'READ_AND_WRITE'" class="setup" @click="$emit('tab-change', '3')">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                            <span v-else class="setup disabled">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="info-item">
                        <div class="info-label">资源节点</div>
                        <div class="info-value">{{ data.regionName }}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-label">防盗链</div>
                        <div class="info-value">{{ data.refererEnabled ? '开启' : '关闭' }}
                            <span v-if="accountType === 'main' || bucketAuth === 'READ_AND_WRITE'" class="setup" @click="$emit('tab-change', '3')">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                            <span v-else class="setup disabled">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="info-item">
                        <div class="info-label">创建时间</div>
                        <div class="info-value">{{ data.create && moment(data.create).format('YYYY-MM-DD HH:mm:ss') }}</div>
                    </div>
                    <div class="info-item" v-show="showFault">
                        <div class="info-label">版本控制</div>
                        <div class="info-value">{{ {Off: '关闭', Enabled: '开启', Suspended: '已暂停'}[data.versionEnabled] }}
                            <span v-if="accountType === 'main' || bucketAuth === 'READ_AND_WRITE'" class="setup" @click="$emit('tab-change', '4')">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                            <span v-else class="setup disabled">
                                <i class="icon iconfont iconedit"></i>
                            </span>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="section3 section">
            <div class="title">域名访问</div>
            <el-table :data="tableData" :border="false">
                <el-table-column label="" prop="access"></el-table-column>
                <el-table-column label="EndPoint（资源池节点）" prop="point"></el-table-column>
                <el-table-column label="桶域名" prop="host"></el-table-column>
                <el-table-column label="HTTPS" prop="https"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
export default {
    name: "bucket-home",
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        bucketAuth: {
            type: String,
            default: ''
        },
        showFault: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            xskyS3Host: '',
            currentPoint: {},
            tableData: [
            ]
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    watch: {
        data({s3Endpoint, s3InternalEndpoint, bucketDomain, bucketInternalDomain, bucketAuth}) {
            let tableData = [
                {access: '内网访问', point: '', host: '', https: '支持'},
                {access: '外网访问', point: '', host: '', https: '支持'},
            ]
            tableData[1].point = s3Endpoint
            tableData[1].host = bucketDomain
            if (!s3InternalEndpoint) {
                tableData.splice(0, 1)
            } else {
                tableData[0].point = s3InternalEndpoint
                tableData[0].host = bucketInternalDomain
            }
            this.tableData = tableData
        }
    },
    created() {
    },
    methods: {
        moment,
    }
}
</script>

<style scoped lang="scss">
.bucket-home {
    background: #f4f4f4;
    position: relative;

}

.bucket-home-data {

    display: flex;
    justify-content: space-around;

    li {
        display: flex;
        flex-direction: column;

    }
}

.bucket-home-info {
    padding: 0;
    background: white;
}

.section1 {
    .bucket-home-info .info-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 12px;
        color: #031129;
        letter-spacing: 0;
        line-height: 12px;
        border-right: 1px solid #E9EBEF;

        .info-label {
            margin-bottom: 30px;
        }

        .info-value {
            .number {
                font-size: 28px;
                color: #333333;
                letter-spacing: 0;
                line-height: 22px;
            }

        }
    }

    .bucket-home-info .info-item:first-child {
        border-left: 1px solid #E9EBEF;

    }
}


.section2 {
    padding-bottom: 0px!important;
    .bucket-home-info .info-item {
        display: flex;
        margin-left: 0px;
        margin-bottom: 10px;
        line-height: 30px;

        .info-label {
            width: 68px;
            color: #999;
        }

        .info-value {
            color: #333333;

            .setup {
                margin-left: 10px;
                cursor: pointer;
                color: #337dff;
            }
            .setup.disabled {
                cursor: not-allowed;
                color: #999;
            }
        }
    }


}

.section {
    background: #fff;
    padding: 20px;
    margin-top: 20px;

    .title {
        font-size: 14px;
        color: #031129;
        line-height: 14px;
        margin-bottom: 14px;
    }
}

.section1 {
    margin-top: 0;
    padding-top: 30px;
}
</style>