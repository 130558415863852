<template>
    <el-dialog title="生成URL" :visible.sync="visible" class="file-url-create">
        <el-form v-loading="loading" :model="form" label-width="100px" ref="form" style="width:400px;" @submit.native.prevent>
            <el-form-item label="文件读写权限">{{ acl }}</el-form-item>
            <el-form-item label="过期时间(秒)" prop="seconds" :rules="[{required: true, message: '请填写过期时间'}]" v-if="acl.indexOf('公共') === -1">
                <el-input-number v-model.trim="form.seconds"  controls-position="right"  :precision="0" :min="180" :max="86400"/>
            </el-form-item>
            <el-form-item label="文件URL">
                <el-input type="textarea" :rows="4" readonly :value="url" ></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-top: -20px;">
                <div class="btns">
                    <span id="copy-url">复制文件URL</span>
                    <span class="line"></span>
                    <span @click="submit">重新生成</span>
                </div>
                <div class="tips" v-show="status !== '3'">提示：该数据处于未解冻/解冻中，无法对其进行下载操作，请先对其进行解冻操作。</div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible=false">取 消</el-button>
            <el-button size="small" type="primary" @click="visible=false">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import copy from 'copy-to-clipboard'
import Clipboard from 'clipboard'
import XEClipboard from 'xe-clipboard'
export default {
    name: "file-create-url",
    props: {

        regionId: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(["currentPoint"]),
    },
    data() {
        return {
            visible: false,
            form: {
                seconds: 180
            },
            url: '',
            bucketName: '',
            key: '',
            s3: null,
            url2: '',
            status: '',
            versionId: '',
            hasAddEvent: false,
            acl: '',
            loading: false
        }
    },
    mounted() {

    },
    methods: {
        handleCopy () {
            if (!this.url) {
                return
            }
            this.$message.success('复制成功！')
        },

        getFileAcl (row) {
            this.loading = true
            this.$postJson('/file/acl/get', {bucketName: row.bucketName, key: row.key, regionId: this.regionId})
                .then(res => {
                    if (res.code === '200') {
                        this.acl = {
                            PRIVATE: '私有',
                            PUBLIC_READ: '公共读',
                            PUBLIC_READ_WRITE: '公共读写'
                        }[res.data]
                        this.generateObjectUrl();
                    }
                }).finally(() => {this.loading = false})
        },
        show({bucketName, key, status, versionId}, s3) {
            this.visible = true
            this.bucketName = bucketName
            this.key = key
            this.s3 = s3
            this.status = status
            this.versionId = versionId
            this.getFileAcl({bucketName, key})
            if (!this.hasAddEvent) {
                setTimeout(() => {
                    document.getElementById('copy-url').addEventListener('click',  (evnt) => {
                        if (XEClipboard.copy(this.url)) {
                            this.$message.success('复制成功！')
                        } else {
                            this.$message.error('The browser is not supported.')
                        }
                    })
                    this.hasAddEvent = true;
                }, 0)
            }
        },
        submit() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.generateObjectUrl()
                }
            })
        },
        generateObjectUrl() {
            const {bucketName, key, form: {seconds}, acl, currentPoint, versionId} = this;
            let data = {
                bucketName,
                key: key,
                seconds,
                versionId,
                regionId: this.regionId
            }
            if (acl.indexOf('公共') > -1) {
                delete data.seconds
            }
            this.$postJson('/file/generateObjectUrl', data)
            .then(res => {
                if (res.code === '200') {
                    if (acl.indexOf('公共') > -1) {
                        this.url = res.data.split('?')[0]
                    } else {
                        this.url = res.data;
                    }
                }
            })
        },
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    text-align: left;
}

.btns {
    font-size: 12px;
    color: #337DFF;
    letter-spacing: 0;
    text-align: left;
    line-height: 24px;
    display: flex;
    align-items: center;

    > span {
        cursor: pointer;
    }

    .line {
        height: 12px;
        width: 1px;
        background: #337DFF;
        padding: 0;
        margin: 0 9.5px;
    }
}

.tips {
    font-size: 12px;
    color: #F04134;
    letter-spacing: 0;
    line-height: 24px;

}

</style>