<template>
    <div class="offline-task">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>离线任务管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="task-page">
            <div class="task-opts">
                <div>
                    <OptButton size="small" type="primary" class="_create" @click="createTask"
                               v-if="accountType === 'main'">新建
                    </OptButton>
                    <OptButton size="small" icon="icon iconfont iconbianzu1711"
                               style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                               @click="loadData(true)"></OptButton>
                </div>
                <div style="display: flex;">
                    <el-date-picker
                        v-model="rangeTimes"
                        type="daterange"
                        :default-time="['00:00:00', '23:59:59']"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        align="right"
                        prefix-icon=""
                        @change="searchLog"
                    ></el-date-picker>
                    <SpaceItem></SpaceItem>
                    <el-input placeholder="请输入任务名称"  @input="handleInputSearch" v-model="taskName" style="width: 120px;" class="input-with-select" >
<!--                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <SpaceItem></SpaceItem>
                    <el-input placeholder="请输入桶名称"  @input="handleInputSearch" v-model="bucketName" style="width: 120px;" class="input-with-select" >
<!--                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <SpaceItem></SpaceItem>
                    <el-select
                        v-model="taskStatus"
                        clearable
                        placeholder="选择任务状态"
                        size="200px"
                        @change="taskStatusChange"
                    >
                        <el-option
                            v-for="item in taskStatusList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"/>
                    </el-select>
                </div>
            </div>
            <div v-loading="loadingData" class="table-container">
                <el-table ref="listTable" :data="tableData" style="width: 100%;">
                    <el-table-column prop="code" label="任务编号"></el-table-column>
                    <el-table-column prop="name" label="任务名称"></el-table-column>
                    <el-table-column prop="create_time" label="任务创建时间">
                        <template slot-scope="{row}">
                            {{ row.createTime ? moment(row.createTime).format('YYYY-MM-DD HH:mm:ss') : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="bucket" label="桶"></el-table-column>
                    <el-table-column prop="step" label="任务状态">
                        <template slot-scope="{row}">{{ row.status === 'CANCEL' ? '已取消' : taskStatusDesc(row.step) }}</template>
                    </el-table-column>
                    <el-table-column prop="action" label="操作">
                        <template slot-scope="{$index, row}">
                            <div style="display: flex;justify-content: flex-start;">
                                <span class="table-action-button" @click="showDetail(1, row)">查看流程</span>
                                <span class="table-action-button" @click="showDetail(2, row)">详情</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                       @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
            </div>
        </div>
        <TaskForm ref="taskFormRef" @reload="loadData"/>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import TaskForm from "@/views/offline/components/TaskForm";
import moment from "moment";
import  {taskStatusDesc} from "@/views/offline/js";

export default {
    name: "offline-task",
    components: {TaskForm},
    data() {
        return {
            loadingData: false,
            tableData: [],
            pager: {
                current: 1,
                size: 10,
                total: 0,
            },
            rangeTimes: [],
            taskName: '',
            bucketName: '',
            taskStatus: '',

            taskStatusList: [
                {label: '定位数据所在盘匣', value: 'POSITION_DATA'},
                {label: '确定离线盘匣', value: 'CONFIRM_RF'},
                {label: '标签打印', value: 'LABEL_PRINT'},
                {label: '取出盘匣', value: 'OFFLINE_RF'},
                {label: '录入存储位置', value: 'STORE_POSITION'},
                {label: '任务完成', value: 'COMPLETE'},
                {label: '任务取消', value: 'CANCEL'},
            ],
            timeout: null
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    mounted() {
        this.loadData()
    },
    methods: {
        moment,
        taskStatusDesc,
        createTask() {
            this.$refs.taskFormRef.show()
        },
        loadData() {
            // /rfTask/page
            const {rangeTimes, taskName, bucketName, taskStatus, pager} = this
            let params = {pageNum: pager.current, pageSize: 10}
            if (rangeTimes && rangeTimes.length === 2) {
                params.startTime = moment(rangeTimes[0]).format('YYYY-MM-DD HH:mm:ss')
                params.endTime = moment(rangeTimes[1]).format('YYYY-MM-DD HH:mm:ss')
            }
            if (taskName) {params.name = taskName}
            if (bucketName) {params.bucket = bucketName}
            if (taskStatus) {params.step = taskStatus}
            this.loadingData = true
            this.$postJson('/rfTask/page', params)
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records
                    this.pager.total = res.data.total
                }
            }).finally(() => {
                this.loadingData = false
            })
        },
        handleSizeChange() {
            this.loadData();
        },
        handleCurrentChange() {
            this.loadData();
        },
        searchLog () {
            this.loadData();
        },
        taskStatusChange () {
            this.loadData();
        },
        showDetail (t, row) {
            this.$router.push('/otd?id=' + row.bid + '&type=' + t)
        },
        handleInputSearch () {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => {
                this.loadData()
            }, 500)
        }
    }
}
</script>

<style scoped>
.offline-task {
    margin: 16px 16px 10px;
    padding: 0 26px;
    background: #fff;
    text-align: left;
    min-height: calc(100% - 36px);
}

.task-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}
</style>