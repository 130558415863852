<template>
    <div class="file" id="__file">
        <el-breadcrumb class="crumb" separator="/">
            <span @click="goBack" class="go-back-icon"><i class="el-icon-arrow-left"/></span>
            <el-breadcrumb-item :to="{ path: '/bucket/list?activeName=2' }" class="bucket-crumb">桶管理</el-breadcrumb-item>
            <el-breadcrumb-item v-if="!parents.length">{{ bucket }}</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{ path: `/bucket/file?userStatus=${userStatus}&regionId=${regionId}&bucket=${bucket}&activeName=2&parent=` }"
                v-if="parents.length">{{
                    bucket
                }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="parents.length" v-for="parent in parents" :key="parent.fullPath"
                                :to="{ path: `/bucket/file?userStatus=${userStatus}&bucket=${bucket}&regionId=${regionId}&parent=${parent.fullPath}&activeName=2` }">
                {{
                    parent.name
                }}
            </el-breadcrumb-item>
        </el-breadcrumb>

        <div class="file-container">
            <el-tabs v-model="activeName" @tab-click="handleTabsClick" style="background: #fff;">
                <el-tab-pane label="概览" v-if="regionType !== 'GDAS'" name="1">
                    <Home :data="currentItem" :showFault="showFault" @tab-change="activeName => this.activeName = activeName" :bucketAuth="bucketAuth"/>
                </el-tab-pane>
                <el-tab-pane label="文件管理" name="2">
                    <el-alert class="alert"
                              v-if="showTip && publicNetSwitch"
                              style="margin: 10px 20px 0;"
                              type="warning" show-icon
                              @close="handleCloseAlert"
                              :closable="true">
                <span slot="title">
                    每份文件解冻所需时间受到文件大小、任务数量等因素影响可能会有差别;您可 <span @click="handleShowSetup"
                                                              :style="'cursor:pointer;' + (IS_SSC ? 'color: #ff8700;' : 'color: #337dff;')">设置解冻完成提醒</span> ，打开提醒后解冻成功您将收到消息。
                </span>

                    </el-alert>
                    <div class="file-page" style="margin: 0 20px;">
                        <div class="file-opts">
                            <div>
                                <OptButton :userIsFrozen="userIsFrozen" size="small" type="default"
                                           @click="dropUploadFile"
                                           v-if="bucketAuth === 'READ_AND_WRITE'">
                                    <!--                        <img class="file-upload" src="../../assets/images/file-upload.png"/>-->
                                    <span>上传文件</span>
                                </OptButton>
                                <OptButton v-if="bucketAuth === 'READ_AND_WRITE'" size="small"
                                           :userIsFrozen="userIsFrozen"
                                           :style="userIsFrozen ? 'margin-right: 10px;' : ''" @click="createFileDir">
                                    新建文件夹
                                </OptButton>
                                <!--          <el-button size="small" icon="el-icon-upload" @click="thawFiles">批量解冻</el-button>-->
                                <el-dropdown
                                    trigger="click" placement="bottom-start"
                                    @visible-change="handleVisibleChange" @command="handleCommand">
                                    <OptButton :userIsFrozen="userIsFrozen" size="small"
                                               style="padding: 6px 10px;!important;"> 批量操作<i
                                        :class="[dropdownVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down', 'el-icon--right']"></i>
                                    </OptButton>
                                    <el-dropdown-menu slot="dropdown" class="batchbtns" v-if="!userIsFrozen">
                                        <el-dropdown-item command="thaw">批量解冻</el-dropdown-item>
                                        <el-dropdown-item command="download">批量下载</el-dropdown-item>
                                        <el-dropdown-item command="delete" v-if="bucketAuth === 'READ_AND_WRITE'">批量删除</el-dropdown-item>
                                        <el-dropdown-item command="addTag" v-if="bucketAuth === 'READ_AND_WRITE'">批量新增标签</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <OptButton size="small" @click="loadData(bucket, fullPath, true)">刷新
                                </OptButton>
                            </div>
                            <div class="search-input">
                                <el-tooltip ref="searchTip" :value="searchFileTip" slot="suffix"
                                            content="默认为当前路径，以/开头为全路径"
                                            placement="top" manual>
                                    <el-input
                                        placeholder="按文件名前缀匹配"
                                        v-model.trim="keyword"
                                        @focus="searchFileTip = true"
                                        @input="searchFileTip = false"
                                        @blur="searchFileTip = false"
                                        @keyup.enter.native="searchFile"
                                        class="input-with-select"
                                    >
                                        <el-button slot="append" icon="el-icon-search" @click="searchFile"></el-button>
                                    </el-input>
                                </el-tooltip>

                                <el-button v-show="esEnabled" size="small" style="margin-left: 10px;" @click="handleAdvancedSearch"> 高级搜索 </el-button>
                            </div>

                        </div>
                        <div v-loading="loadingData">
                            <el-table ref="tableRef" :data="tableData" style="width: 100%"
                                      @selection-change="handleSelectionChange">
                                <el-table-column type="selection" :selectable="checkSelectable"
                                                 width="45px"></el-table-column>
                                <el-table-column label="文件名称" show-overflow-tooltip>
                                    <FileName slot-scope="{ row }" @click.native="checkBucketFiles(row)"
                                              :filename="row.displayName"
                                              :directory="row.directory"/>
                                </el-table-column>
                                <el-table-column label="文件大小">
                                    <template slot-scope="{ row }">
                                        <span v-if="row.directory">{{ handleDirectorySize(row) }}</span>
                                        <span v-else>{{ row.sizeStr }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="文件数量">

                                    <template slot-scope="{ row }">
                                        <span v-if="row.directory">{{
                                                row.meta?.userMetadata?.folder_count || '-'
                                            }}</span>
                                        <span v-else>-</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="存储类型" align="center" width="120">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.directory">-</span>
                                        <span v-else-if="scope.row.meta && scope.row.meta.storageClass === 'STANDARD'">标准存储</span>
                                        <span v-else-if="scope.row.meta && scope.row.meta.storageClass === 'ARCHIVE'">归档存储</span>
                                        <span v-else-if="scope.row.meta && scope.row.meta.storageClass === 'INTELLIGENT'">
                                            智能存储
                                        </span>
                                        <span v-else-if="scope.row.meta && scope.row.meta.storageClass === 'GLACIER'">深度归档</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="lastModified">
                                    <template slot="header" slot-scope="scope">

                                        <span>更新时间</span>

                                        <el-tooltip
                                            placement="top"
                                            trigger="hover"
                                            popper-class="pop12"
                                        >
                                            <div slot="content">
                                                <p>文件夹显示更新统计数据的时间点</p>
                                            </div>

                                            <span class="vol-tip"></span>

                                        </el-tooltip>

                                    </template>
                                    <template slot-scope="{row}">
                                        <span v-if="row.directory"> {{ row.meta?.userMetadata?.stat_time }} </span>
                                        <span v-else>
                                            {{ row.lastModified && moment(row.lastModified).format('YYYY-MM-DD HH:mm:ss') }}
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column width="200px">
                                    <template slot="header" slot-scope="scope">

                                        <span>操作</span>

                                        <el-tooltip
                                            placement="top"
                                            trigger="hover"
                                            popper-class="pop12"
                                        >
                                            <div slot="content">
                                                <p v-show="restoreRemindStatus === '2'">可前往使用设置中开启解冻完成提醒;</p>
                                                <p>智能存储、深度归档类型不支持删除;</p>
                                                <p>文件夹统计数据为T+2小时手动更新数据</p>
                                            </div>

                                            <span class="vol-tip"></span>

                                        </el-tooltip>

                                    </template>
                                    <template slot-scope="{ $index, row }">
                                        <!--                            <el-link :disabled="userIsFrozen"  :underline="false" class="op-link" v-if="!row.directory && row.status == '1'"-->
                                        <!--                                     @click.stop="preThawFile($index, row)">解冻-->
                                        <!--                            </el-link>-->
                                        <div class="file-table-action-list" style="margin-left: -6px;">
                                            <el-button v-if="!row.directory && bucketAuth === 'READ_AND_WRITE'"  size="small" type="text" @click="handleEditTags(row)">编辑标签</el-button>
                                            <el-button size="small" type="text"
                                                       v-if="(row.status == '1' || row.directory)"
                                                       :loading="thawLoading[$index]" @click="preThawFile($index, row)">
                                                解冻
                                            </el-button>

                                            <el-button size="small" type="text"
                                                       v-if="(row.directory)"
                                                       :disabled="row.meta?.userMetadata?.stat_flag === 'stated' || row.meta?.userMetadata?.stat_flag === 'stating'"
                                                       :loading="thawLoading[$index]"
                                                       @click="directoryStatistics($index, row)">
                                                统计 <span
                                                v-show="row.meta?.userMetadata?.stat_flag === 'stating'">中</span>
                                            </el-button>


                                            <el-button size="small" type="text" class="op-link"
                                                       v-if="!row.directory && row.status == '2'"
                                                       disabled>解冻中
                                            </el-button>
                                            <el-button :disabled="userIsFrozen" size="small" type="text" class="op-link"
                                                       v-if="row.status == '3' && !row.directory"
                                                       @click.stop="downloadFile($index, row)">下载
                                            </el-button>
                                            <el-button  size="small" type="text" class="op-link"
                                                        disabled
                                                       v-if="row.status == '4' && !row.directory"
                                                       >已离线
                                            </el-button>

                                            <el-button
                                                v-if="isShowDeleteBtn(row) && (!row.directory || row.isEmpty) && bucketAuth === 'READ_AND_WRITE'"
                                                size="small" type="text" @click="handleDelete(row)">删除</el-button>
                                            <el-tooltip
                                                v-if="!isShowDeleteBtn(row) && (!row.directory || row.isEmpty) && bucketAuth === 'READ_AND_WRITE'"
                                                placement="top"
                                                trigger="hover"
                                                popper-class="pop12"
                                            >
                                                <div slot="content">智能存储、深度归档类型不支持删除</div>
                                                <span style="cursor: not-allowed;color: #acacac;"> 删除 </span>
                                                <!--                                <el-button size="small" type="text" :disabled="true" > 删除 </el-button>-->
                                            </el-tooltip>


<!--                                             <el-button type="text"  @click.stop="handleDeleteFile([row])">删除</el-button>-->
                                            <MoreAction
                                                v-show="!row.directory && regionType !== 'GDAS'"
                                                @select="action => {handleMoreAction(action, row)}"
                                                :list="moreActionList"
                                                :id="'file_' + $index"/>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <Pager
                                class="bs-pager"
                                v-model="pager"
                                layout="sizes,page"
                                :moreBatch="pager.hasNextPatch"
                                :sizes="[5, 10, 20, 50, 100]"
                                @currentChange="handleCurrentChange"
                                @sizeChange="handleSizeChange"
                            ></Pager>
                        </div>
                        <el-dialog custom-class="changeConfirm" title="新建文件夹" top="35vh" :visible.sync="showCreate"
                                   width="600px"
                                   :close-on-click-modal="false"
                                   @closed="createDialogClose">
                            <el-form
                                :model="forlderForm"
                                :rules="forlderRules"
                                ref="forlderForm"
                                @validate="onValidate"
                                @submit.native.prevent
                                class="create-form"
                                label-width="80px"
                                style="width:410px;"
                            >
                                <el-form-item label="文件夹名称" prop="fileName">
                                    <el-input v-model.trim="forlderForm.fileName" maxlength="200"
                                              @input="$debounce('validInput', 300)"
                                              placeholder="请输入" class="folder-input">
                  <span slot="suffix" class="el-input__count">
                    <span class="el-input__count-inner">
                      {{ forlderForm.fileName.length + "/200" }}
                    </span>
                  </span>
                                        <i v-if="formValidate" slot="suffix" class="el-input__icon el-icon-success"
                                           style="color: #5DC560"></i>
                                    </el-input>
                                    <div v-if="formValidate == null || formValidate" class="tip-show"
                                         style="font-size: 12px; color: #999;line-height: 22px;">
                                        1、长度不超过200<br/>
                                        2、用'/'分割路径，可快速创建子目录<br/>
                                        3、不允许文件夹名称为空、连续'/'、 以'/'开头、以'/'结尾<br/>
                                        4、不支持输入'.'、'#'、'&'<br/>
                                    </div>
                                </el-form-item>
                            </el-form>
                            <span slot="footer" class="dialog-footer">
              <el-button size="small" @click="createDialogClose" :disabled="cancelButtonDisabled">取消</el-button>
              <el-button size="small" type="primary" :loading="createLoading" @click="doCreate">确定</el-button>
            </span>
                        </el-dialog>

                        <el-dialog :close-on-click-modal="false" title="上传文件" custom-class="uploadFile" top="35vh"
                                   :visible.sync="showDropDialog" width="600px" @close="showDropDialog=false">
                            <DropZone @change="dropFileChangeConfirm" ref="dropZoneRef" :storagePlatformType="storagePlatformType"></DropZone>
                            <span slot="footer" class="dialog-footer">
                                    <el-button size="small"  @click="showDropDialog = false">取消</el-button>
                                    <el-button size="small" type="primary" @click="() => {this.$refs.dropZoneRef.submit()}">上传</el-button>
                                </span>
                        </el-dialog>
                        <el-dialog custom-class="changeConfirm" top="35vh" title="文件上传" :close-on-click-modal="false"
                                   :visible.sync="showConfirmFileChange" width="484px"
                                   @close="showConfirmFileChange=false">
                            <div class="cc-body"><i class="el-alert__icon el-icon-warning"></i>由于深度归档永久保留特性，文件上传成功后不可更改，是否确认上传？
                            </div>
                            <span class="no-again-tip">
                            <el-checkbox v-model="noAgainTip" @change="noTipChange">不再提醒</el-checkbox>
                          </span>
                            <div class="cc-footer">

                                <el-button @click="dropFileChangeClose">取消</el-button>
                                <el-button type="primary" @click="confirmUploadFiles">确定</el-button>
                            </div>
                        </el-dialog>

                        <el-dialog custom-class="changeConfirm" top="35vh" :title="batchTip"
                                   @close="showThawDialog = false"
                                   :close-on-click-modal="false"
                                   :visible.sync="showThawDialog" width="484px">
                            <div class="cc-body"><i class="el-alert__icon el-icon-warning"></i>当前排队人数为{{
                                    queueUserCount
                                }}人，排队文件为{{ queueKeyCount }}个，文件解冻完成后，请及时下载。
                            </div>
                            <div class="cc-footer thaw">
                                <el-button @click="showThawDialog = false">取消</el-button>
                                <el-button type="primary" @click="confirmThawFile">确定</el-button>
                            </div>
                        </el-dialog>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="碎片管理" name="5">
                    <Fragment v-if="activeName==='5'" :bucket="bucket" :regionId="regionId" @complete-abort="loadDetail"/>
                </el-tab-pane>
                <el-tab-pane label="权限管理" v-if="regionType !== 'GDAS'" name="3">
                    <Auth :bucket="bucket" ref="authRef" :regionId="regionId" :bucketAuth="bucketAuth"/>
                </el-tab-pane>
                <el-tab-pane label="容错管理" v-if="showFault" name="4">
                    <Fault @version-change="setBucketVersionSet" :bucket="bucket" ref="faultRef" :bucketAuth="bucketAuth" :regionId="regionId"/>
                </el-tab-pane>
                <el-tab-pane label="生命周期" v-if="regionType === 'E37DEG' || regionType === 'SUPERSTOR'" name="6">
<!--                <el-tab-pane label="生命周期"  name="6">-->
                    <LifeCycle v-if="activeName === '6'" :bucket="bucket" :regionId="regionId"/>
                </el-tab-pane>
                <el-tab-pane label="图片处理" name="7">
                    <ImageSetting v-if="activeName === '7'" :bucket="bucket" :regionId="regionId"/>
                </el-tab-pane>
            </el-tabs>
        </div>

        <Url ref="urlRef" :regionId="regionId" :acl="currentItem.bucketAcl"/>

        <SetAuth
            ref="setAuthRef"
            :regionId="regionId"
            @set-auth="() => {this.loadData(this.bucket, this.fullPath, true)}"/>

        <FileHistory
            ref="fileHistoryRef"
            :regionId="regionId"
            :bucketAuth="bucketAuth"
            @file-restore="handleVersionRestore"
            @file-download="handleVersionDownload"
            @file-delete="row => {this.handleDeleteFile([row])}"
        />

        <edit-tag ref="editTagRef"/>

        <add-tag ref="addTagRef" @complete="loadData"/>

        <FileDetail ref="fileDetailRef"/>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import service from "@/utils/request";
import {isIE, is360} from "@/utils/env";
import {S3} from "@/utils/aws";
import moment from 'moment'
import {getRegionList} from '@/utils/session'
import {findMeta, addMeta, deleteMeta} from "@/utils/session";
import vm from "@/plugin/eventUtil";
import Home from './Home'
import Auth from './Auth'
import Url from './url'
import SetAuth from './setAuth'
import Fault from './fault'
import Fragment from "./fragment";
import MoreAction from "@/views/components/MoreAction";
import FileHistory from "./FileHistory";
import LifeCycle from './LifeCycle'
import editTag from "@/views/bucket/components/editTag";
import addTag from "@/views/bucket/components/addTag";
import ImageSetting from './components/imageSetting'
import FileDetail from "./components/fileDetail";
import {bytesToSize} from "@/utils";
import storage from "@/utils/storage";

let timeout = null
export default {
    name: "File",
    components: {Home, Auth, Url, SetAuth, Fault, Fragment, MoreAction, FileHistory, LifeCycle, editTag, addTag, ImageSetting, FileDetail},
    data() {
        const valideForlder = async (rule, value, callback) => {
            const fileName = value.replace(/(^\s*)|(\s*$)/g, "");
            if (!fileName) {
                callback(new Error("文件夹名称不能为空"));
                return false;
            }
            if (fileName.length > 200) {
                callback(new Error("文件夹名称不能超过200个字符"));
                return false;
            }
            ///^[0-9a-zA-Z!\-_\.\*'\(\)/]+$/.test('a.ad2323048.d/d/')
            if (!/^([^\x00-\xff]*[\w\~\!@$%^*()\[\]{}\?'"<>\|=+\/\-`:;]*)+$/.test(fileName)) {
                callback(new Error("文件夹名称包含非法字符"));
                return false;
            }
            if (fileName.indexOf("//") > 0 || fileName.startsWith("/") || fileName.endsWith("/")) {
                callback(new Error("文件夹名不允许连续‘/’、 以‘/’开头、以‘/’结尾"));
                return false;
            }
            const {bucket, fullPath, regionId} = this;
            const key = fullPath ? `${fullPath}/${fileName}/` : `${fileName}/`;
            if (key.length > 1024) {
                callback(new Error("超出全路径1024个字符限制"));
            }
            if (timeout) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => {
                service.postJson("/file/exist", {
                    bucketName: bucket,
                    key,
                    regionId
                }).then((res) => {
                    if (res.code === '200' && res.data) {
                        callback(new Error("该目录已存在同名文件"));
                    } else {
                        callback()
                    }
                }).catch((err) => {
                    callback(new Error(err.message));
                })
            }, 2000)
        }
        return {
            regionType: '', // 资源池类型 GDAS XSKY 。。。
            useDedicated: false,
            forlderForm: {
                fileName: "",
            },
            currentItem: {},
            loadingData: false,
            tableData: [],
            selectedRow: [],
            pager: {
                current: 1,
                size: 10,
                hasNext: true,
                hasNextPatch: true,
                total: 0,
            },
            keyword: "",
            bucket: "",
            parents: [],
            fullPath: "",
            fileName: "",
            showCreate: false,
            createLoading: false,
            showDropDialog: false,
            showConfirmFileChange: false,
            noAgainTip: false,
            dropFiles: [],
            dropdownVisible: false,
            showThawDialog: false,
            thawFileIndex: 0,
            queueUserCount: 0,
            queueKeyCount: 0,
            formValidate: null,
            preThawTableData: [],
            batchTip: "",
            searchFileTip: false,
            cancelButtonDisabled: false,
            regionId: '',
            userIsFrozen: false,
            userStatus: '',
            thawLoading: [false, false, false, false, false, false, false, false, false, false],
            thawIndex: [],
            dbClick: 0,
            bucketAuth: '',
            showTip: false,
            forlderRules: {
                fileName: [{validator: valideForlder, trigger: "change"}]
            },
            activeName: '2',
            versionFileToRestore: {},
            vesionSetup: ''
        };
    },
    computed: {
        ...mapGetters(["token", "userData", "uploadNoTip", "currentPoint", 'accountId', 'accountType', 'restoreRemindStatus', 'esEnabled', 'publicNetSwitch']),
        optDisabledTip() {
            const {userIsFrozen} = this
            return userIsFrozen ? '账号被冻结' : ''
        },
        storagePlatformType () {
            const {storagePlatformType} = this.currentItem
            return localStorage.getItem('regionType')
        },
        showFault () {
            return this.regionType !== 'E37DEG' && this.regionType !== 'GDAS' && this.regionType !== 'SUPERSTOR'
        },
        moreActionList () {
            let result = ['acl', 'url']
            if (this.bucketAuth !== 'READ_AND_WRITE') {
                result =  ['url']
            }
            if (this.showFault) {
                result.push('history')
            }
            return result
        }
    },
    watch: {
        accountId(val) {
            console.log('accountid3', val)
            if (val) {
                this.getBucketAuth(val)
            }
        },
        accountType(val) {
            if (val === 'main') {
                this.bucketAuth = 'READ_AND_WRITE'
            }
        },
        restoreRemindStatus(val) {
            console.log('restoreRemindStatus', val)
        }
    },
    mounted() {
        setTimeout(() => {
            this.$uploadTask.init({}, '__file');
        }, 1000)
        this.loadDetail();
    },
    created() {
        this.regionType = localStorage.getItem('regionType')
        if (this.regionType === 'GDAS') {
            this.activeName = '2'
        }

        this.init();
        if (this.accountType === 'main') {
            this.bucketAuth = 'READ_AND_WRITE'
        } else if (this.accountId) {
            this.getBucketAuth(this.accountId)
        }

        // this.loadDetail();
        const listener = () => {
            const {bucket, fullPath} = this;
            this.loadData(bucket, fullPath, true);
        };
        this.$uploadTask.addUploadListener(listener);
        // 通过hook监听组件销毁钩子函数，并取消监听事件
        this.$once("hook:beforeDestroy", () => {
            this.$uploadTask.removeUploadListener(listener);
        });

        this.showTip = !sessionStorage.getItem('CLOSE_TIP_1');
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    },
    methods: {
        isShowDeleteBtn ({meta, storageClass}) {
            return storageClass === 'STANDARD'
            // return storageClass === 'STANDARD' || (storageClass === 'INTELLIGENT' && meta.lastModified && this.moment(meta.lastModified).add(90, 'd')>this.moment())
        },
        // 高级搜索
        handleAdvancedSearch () {
            this.$router.push('/advancedSearch?bucket=' + this.bucket + '&bucketAuth=' + this.bucketAuth)
        },
        handleEditTags (row) {
            // let data = {
            //     bucketName: this.bucket,
            //     key: row.key,
            //     versionId: null,
            //     regionId: this.regionId
            // }
            // this.$postJson('/file/getTagging', data)
            // .then(res => {
            //     console.log(res)
            //     if (res.code === '200') {
            //
            //     }
            // })
            this.$refs.editTagRef.show({...row, versionId: null, regionId: this.regionId});

        },
        setBucketVersionSet (val) {
            this.vesionSetup = val
        },
        // 更多操作 设置权限 acl  生成链接 url  历史版本history
        handleMoreAction (action, row) {
            switch (action) {
                case 'acl':
                    this.setFileAcl(row)
                    break;
                case 'url':
                    this.createUrl(row)
                    break;
                case 'history':
                    this.showHistory(row)
                    break;
            }
        },
        createUrl (row) {
            this.$refs.urlRef.show(row, this.s3)
        },
        setFileAcl (row) {
            this.$refs.setAuthRef.show(row, this.s3)
        },
        showHistory (row) {
            this.$refs.fileHistoryRef.show(row)
        },
        handleTabsClick(tab) {
            const {bucket, fullPath} = this
            if (tab.name === '1') {
                this.loadDetail()
            } else if (tab.name === '2') {
                this.loadData(bucket, fullPath, true);
            } else if (tab.name === '3') {
                this.$refs.authRef.show()
            } else if (tab.name === '4') {

            }
            this.$refs.faultRef.show()
        },
        handleCloseAlert() {
            sessionStorage.setItem('CLOSE_TIP_1', true)
        },
        dirNavigate(url) {
            this.$router.push((url))
        },
        getBucketAuth(accountId) {
            if (accountId) {
                const {regionId, bucket} = this;
                this.$postJson('/auth/user/bucket/get', {accountId, bucketList: [bucket], regionId})
                    .then(res => {
                        console.log(res)
                        if (res.code === '200' && res.data && res.data.length > 0) {
                            if (this.accountType !== 'main') {
                                this.bucketAuth = res.data[0].auth // READ  READ_AND_WRITE
                                this.$uploadTask.showEntrance(this.bucketAuth === 'READ_AND_WRITE')
                            }
                        }
                    })
            }
        },
        moment,
        handleDelay(inde, row) {
            const {regionId, bucket} = this;
            let data = {bucketName: bucket, key: row.key, regionId}
            this.$postJson('/file/restore/delay', data)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功');
                    }
                })
        },
        init() {
            const {bucket, parent, keyword = "", regionId, userStatus, activeName} = this.$route.query;
            this.regionId = regionId;
            this.keyword = keyword;
            this.bucket = bucket;
            this.parents = [];
            this.userStatus = userStatus;
            this.userIsFrozen = userStatus === 'SUSPENDED';
            let fullPath = "";
            if (activeName) {
                this.activeName = activeName
            }
            if (parent) {
                const list = parent.split("/");
                for (let i = 0; i < list.length; i++) {
                    fullPath = fullPath ? `${fullPath}/${list[i]}` : `${list[i]}`;
                    this.parents.push({
                        fullPath: fullPath,
                        name: list[i],
                    });
                }
            }
            this.fullPath = fullPath;
            this.pager.current = 1;
            this.pager.hasNext = true;
            this.loadData(bucket, this.fullPath, true);
        },

        goBack() {
            const {regionId = '', userStatus} = this;
            if (this.parents.length > 1) {
                this.$router.push(`/bucket/file?userStatus=${userStatus}&regionId=${regionId}&bucket=${this.bucket}&parent=${this.parents[this.parents.length - 2].fullPath}`);
            } else if (this.parents.length == 1) {
                this.$router.push(`/bucket/file?userStatus=${userStatus}&regionId=${regionId}&bucket=${this.bucket}&parent=`);
            } else {
                this.$router.push(`/bucket/list`);
            }
        },
        onValidate(param, valid) {
            if (valid) {
                this.formValidate = true;
            } else {
                this.formValidate = false;
            }
        },
        validInput() {
            // this.$refs["forlderForm"].validate();
        },
        handleSizeChange(e) {
            this.pager.current = 1;
            this.pager.hasNext = true;
            this.pager.size = e;
            const {bucket, fullPath} = this;
            this.loadData(bucket, fullPath);
        },
        handleCurrentChange(e) {
            if (this.pager.current < e && !this.pager.hasNext) {
                this.$message.warning("没有更多了");
                return;
            }
            this.pager.current = e;
            const {bucket, fullPath} = this;
            this.loadData(bucket, fullPath);
        },
        checkSelectable(row) {
            return true;
        },
        handleSelectionChange(e) {
            this.selectedRow = [...e];
        },
        loadData(bucketName, prefix, init = false) {
            const {keyword} = this;
            if (prefix && prefix.substr(prefix.length - 1) !== "/") {
                prefix = `${prefix}/`;
            }
            if (keyword) {
                if (!prefix) {
                    prefix = `${keyword}`;
                } else {
                    prefix = `${prefix}${keyword}`;
                }
            }
            this.loadingData = true;
            const {
                pager: {current, size},
                regionId
            } = this;

            this.$store
                .dispatch("file/getFileList", {
                    prefix,
                    bucketName,
                    current,
                    size,
                    init,
                    regionId
                })
                .then((res) => {
                    const {list, hasNext, hasNextPatch, total} = res;
                    this.tableData = [
                        ...list.map((item) => {
                            this.$set(item, "canDel", false);
                            return item;
                        }),
                    ];
                    this.pager.hasNextPatch = hasNextPatch;
                    this.pager.hasNext = hasNext;
                    this.pager.total = total;
                    this.loadingData = false;
                    this.loadMeta(this.tableData, true);
                    this.loadDetail();
                    if (hasNext) {
                        setTimeout(() => {
                            this.loadMataLazy(bucketName, prefix)
                        }, 50 * size)
                    }
                });

        },
        loadDetail() {
            const {bucket: bucketName, regionId, useDedicated} = this;
            this.$postJson("bucket/get", {bucketName, regionId}).then((res) => {
                if (!res.data) {
                    this.timer = setTimeout(this.loadDetail.bind(this), 3000);
                    return;
                }

                this.currentItem = res.data;
                if (localStorage.getItem('bucket_create')) {
                    this.currentItem.create = localStorage.getItem('bucket_create')
                }
                if (!useDedicated) {
                    this.s3 = new S3(res.data.s3Host, res.data.accessKey, res.data.secretKey);
                    if (res.data.privateHost) {
                        this.checkDedicated(res.data)
                    }
                }
            });
        },
        // 检查是否有专线
        checkDedicated({privateHost, accessKey, secretKey, s3Host}) {
            let s3 = new S3(privateHost, accessKey, secretKey)
            s3.getS3Instance().listObjects({Bucket: this.bucket, MaxKeys: 2}, (err, data) => {
                if (!err) {
                    this.s3 = s3
                    this.useDedicated = true
                }
            })
        },
        loadMeta(tableData, isSet) {
            const {regionId} = this;
            for (let i = 0; i < tableData.length; i++) {
                const {bucketName, key} = tableData[i];
                let meta = findMeta(bucketName + key)
                if (!1) {// 检查是否有缓存过meta信息 暂时不走缓存流程
                    isSet && this.$set(tableData[i], "status", meta.status);
                } else {
                    const data = {bucketName, key, regionId};
                    this.$postJson("/file/meta", data).then((res) => {
                        this.$set(tableData[i], "status", res.data.status);
                        this.$set(tableData[i], "meta", res.data.meta);
                        this.$set(tableData[i], "storageClass", res.data.meta.storageClass);
                        if (tableData[i].directory) {
                            this.$set(tableData[i], "isEmpty", res.data.meta?.userMetadata?.empty === 'true');
                        }
                        // meta信息缓存起来
                        // addMeta({id: bucketName + key, status: res.data.status})
                    });
                }
            }
        },
        loadMataLazy(bucketName, prefix) { // 缓存下一页meta数据
            const {keyword} = this;
            if (prefix && prefix.substr(prefix.length - 1) !== "/") {
                prefix = `${prefix}/`;
            }
            if (keyword) {
                if (!prefix) {
                    prefix = `${keyword}`;
                } else {
                    prefix = `${prefix}${keyword}`;
                }
            }
            const {
                pager: {current, size},
                regionId
            } = this;

            this.$store
                .dispatch("file/getFileList", {
                    prefix,
                    bucketName,
                    current: current + 1,
                    size,
                    init: false,
                    regionId
                })
                .then(res => {
                    const {list} = res;
                    this.loadMeta(list, false)
                })
        },
        // 没有删除功能了， 接口不用了
        loadKeyCount() {
            const {tableData} = this;
            for (let i = 0; i < tableData.length; i++) {
                const item = tableData[i];
                if (item.directory) {
                    const data = {
                        bucketName: item.bucketName,
                        key: item.key,
                    };
                    this.$post("/file/keyCount", data).then((res) => {
                        this.$set(tableData[i], "canDel", res.data <= 1);
                    });
                }
            }
        },
        searchFile() {
            let {bucket, fullPath, keyword, regionId, userStatus} = this;
            this.pager.current = 1;
            if (keyword && keyword.indexOf("/") > -1) {
                if (keyword.substr(0, 1) === "/") {
                    keyword = keyword.substr(1);
                    fullPath = "";
                }
                const kList = keyword.split("/");
                keyword = kList.splice(kList.length - 1, 1).join("");
                const displayName = kList.join("/");
                const parent = fullPath ? `${fullPath}${displayName}` : `${displayName}`;
                if (!parent) {
                    this.$router.push({
                        path: "/bucket/file",
                        query: {
                            bucket: bucket,
                            keyword: keyword,
                            regionId,
                            userStatus,
                            parent: parent
                        },
                    });
                } else {
                  this.$router.push({
                    path: "/bucket/file",
                    query: {
                      bucket: bucket,
                      keyword: keyword,
                      regionId,
                      userStatus,
                      parent: parent
                    },
                  });
                }
            } else {
                this.loadData(bucket, fullPath, true);
            }
        },
        checkBucketFiles(row) {
            const {bucket, fullPath, regionId, userStatus} = this;
            const {displayName} = row;
            const parent = fullPath ? `${fullPath}/${displayName}` : `${displayName}`;
            if (row.directory) {
                this.$router.push({
                    path: "/bucket/file",
                    query: {
                        bucket: bucket,
                        regionId,
                        userStatus,
                        parent: parent,
                        activeName: '2'
                    },
                });
            } else {
                this.$refs.fileDetailRef.show(row)
            }
        },
        dropUploadFile() {
            this.showDropDialog = true;
        },
        dropDialogClose() {
            this.showDropDialog = false;
        },
        noTipChange(value) {
            this.$store.dispatch("user/uploadConfirmNoTip", {value});
        },
        dropFileChangeConfirm(files) {
            // this.$confirm("文件即将上传，上传后成功后文件不可更改，是否确定上传？", "文件上传", {
            //   confirmButtonText: "确认上传",
            //   cancelButtonText: "取消上传",
            //   type: "warning",
            // })
            // .then(() => {
            //   this.dropFileChange(files);
            // })
            // .catch(() => {});
            if (!files || files.length === 0) {
                return
            }
            if (!this.uploadNoTip && files[0].storageClass === 'GLACIER') {
                this.dropFiles = [...files];
                this.showConfirmFileChange = true;
                this.dropDialogClose();
            } else {
                this.dropFileChange(files);
            }
        },
        dropFileChangeClose() {
            this.showConfirmFileChange = false;
            this.showDropDialog = true
            this.dropUploadFile();
        },
        confirmUploadFiles() {
            this.showConfirmFileChange = false;
            this.showDropDialog = false
            const files = [...this.dropFiles];
            this.dropFileChange(files);
            this.dropFiles = [];
        },
        dropFileChange(files = []) {
            console.log('files：',files)
            const {bucket, fullPath, currentItem, regionId} = this;
            const fileList = [];
            if (files.length > 100) {
                this.$message.error("文件个数超过100个，请使用SDK上传");
                return;
            }
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.name.length > 162 && file.type !== 'dir') {
                    this.$message.error("文件名称长度不能超过162个字符");
                    return;
                }
                if (file.name.length > 200 && file.type === 'dir') {
                    this.$message.error("文件夹名称不能超过200个字符");
                    return;
                }
                fileList.push({
                    name: file.name,
                    fullPath: file.fullPath,
                    size: file.size,
                    type: file.type,
                    bucket: bucket,
                    parent: fullPath,
                    raw: file,
                    xskyS3Host: this.useDedicated ? currentItem.privateHost : currentItem.s3Host,
                    accessKey: currentItem.accessKey,
                    secretKey: currentItem.secretKey,
                    regionId,
                    StorageClass: this.storagePlatformType === 'E37DEG' || this.storagePlatformType === 'SUPERSTOR' ? file.storageClass : currentItem.storageClass,
                    vesionSetup: this.vesionSetup, // 桶的版本控制
                    tagList: file.tagList
                });
            }
            this.dropDialogClose();
            this.$uploadTask.showList();
            this.$uploadTask.push(fileList);
            this.addTags(files)
        },
        addTags (files) {
            if (!files || files.length === 0) {
                return
            }
            let tagList = files[0].tagList
            if (!tagList || tagList.length === 0) {
                return;
            }
            this.$postJson('/tag/add', {tagList})
            .then(res => {
                if (res.code === '200') {
                    console.log('添加标签')
                }
            })
        },
        createFileDir() {
            this.showCreate = true;
        },
        createDialogClose() {
            this.showCreate = false;
            this.createLoading = false
            this.formValidate = null;
            this.forlderForm.fileName = "";
            this.$refs["forlderForm"].clearValidate();
        },
        doCreate() {
            const {bucket, fullPath} = this;
            let {fileName} = this.forlderForm;
            fileName = fileName.replace(/(^\s*)|(\s*$)/g, "");
            this.createLoading = true;
            this.cancelButtonDisabled = true;
            this.$refs["forlderForm"].validate((valid) => {
                if (valid) {
                    const key = fullPath ? `${fullPath}/${fileName}/` : `${fileName}/`;
					if (this.$isClintUpload()) {
						this.s3
							.createDir({
								bucket: bucket,
								key: key,
							})
							.then((res) => {
								this.createLoading = false;
								this.cancelButtonDisabled = false;
								this.createDialogClose();
								const {bucket, fullPath} = this;
								this.loadData(bucket, fullPath, true);
							})
							.catch((e) => {
								this.createLoading = false;
								this.cancelButtonDisabled = false;
								this.createDialogClose();
								this.$message.error(e.message || "创建失败，请重试");
							});
					} else {
						this.$postJson('/file/create/dir', {
							bucketName: bucket,
							key
						})
							.then(res => {
								if (res.code === '200') {
									this.createLoading = false;
									this.cancelButtonDisabled = false;
									this.createDialogClose();
									const {bucket, fullPath} = this;
									this.loadData(bucket, fullPath, true);
								}
							})
							.catch((e) => {
								this.createLoading = false;
								this.cancelButtonDisabled = false;
								this.createDialogClose();
								this.$message.error(e?.message || "创建失败，请重试");
							});
					}

                } else {
                    this.createLoading = false;
                    this.cancelButtonDisabled = false;
                }
            });
        },

        handleVersionRestore (row) {
            this.versionFileToRestore = row
            this.preThawFile('version', row)
        },
        handleVersionDownload (row) {
            this.downloadFile('', row)
        },
        // 文件夹统计
        directoryStatistics(index, row) {
            // this.$get(`/file/stat?bucketName=${this.bucket}&key=${row.key}`)
			this.$postJson('/file/stat', {bucketName: this.bucket, key: row.key})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('如文件数量较多，更新时间略有延迟')

                        setTimeout(() => {
                            this.loadData(this.bucket, this.fullPath, true)
                        }, 1000)
                    }
                })
        },

        handleDirectorySize(row) {
            if (!row.meta || !row.meta.userMetadata)  {
                return '-'
            }
            const {folder_count = 0, folder_size = 0, stat_time} = row.meta?.userMetadata
            /**
             * "folder_count":"96",
             * "folder_size":"951",
             * "stat_flag":"stated",
             * "stat_time":"2023-07-25 15:37:27"
             * **/
            if (folder_size > 0) {
                return bytesToSize(folder_size)
            }

            return '-'

        },

        async preThawFile(index, row) {
            this.thawFileIndex = index;
            const {regionId} = this;
            if (row.directory) {
                const res = await this.$postJson('/file/list', {regionId, bucketName: row.bucketName, prefix: row.key})
                if (res.code === '200' && res.data.objects.length === 0) {
                    this.$message.warning('文件夹为空')
                    return
                }
            }
            this.$postJson("/file/restore/queue?regionId=" + regionId).then((res) => {
                this.queueUserCount = res.data.userCount;
                this.queueKeyCount = res.data.keyCount;
                if (this.queueUserCount == 0) {
                    this.confirmThawFile();
                } else {
                    this.batchTip = "文件解冻";
                    this.showThawDialog = true;
                }
            });
        },

        confirmThawFile() {
            if (Date.now() - this.dbClick < 1000) { // 防止二次点击
                this.showThawDialog = false;
                return;
            }
            const {tableData, thawFileIndex, preThawTableData, versionFileToRestore} = this;
            if (tableData.length <= thawFileIndex) {
                return;
            }
            this.showThawDialog = false;
            this.dbClick = Date.now();
            if (thawFileIndex === 'version') {
                const {bucketName, key, versionId} = versionFileToRestore;
                this.doThawFiles(bucketName, [{key, versionId}]);
            } else if (thawFileIndex != "批量") {
                const {bucketName, key} = tableData[thawFileIndex];
                this.doThawFiles(bucketName, [{key, verisonId: null}]);
            } else {
                this.doThawFiles(
                    this.bucket,
                    preThawTableData.map((item) => ({key: item.key, versionId: null}))
                );
            }
        },
        thawFiles() {
            const {selectedRow, regionId} = this;
            if (selectedRow.length <= 0) {
                this.$message.warning("请至少选择一个");
                return;
            }
            const thawList = [];
            for (let i = 0; i < selectedRow.length; i++) {
                const row = selectedRow[i];
                if (row.status === "1" || row.directory) {
                    thawList.push(row);
                }
            }
            if (thawList.length === 0) {
                this.$message.warning("所选文件不包含解冻文件，无需进行解冻");
                return;
            }

            this.$postJson("/file/restore/queue?regionId=" + regionId).then((res) => {
                this.queueUserCount = res.data.userCount;
                this.queueKeyCount = res.data.keyCount;
                if (this.queueUserCount == 0) {
                    this.doThawFiles(
                        this.bucket,
                        thawList.map((item) => ({key: item.key, versionId: null}))
                    );
                } else {
                    this.batchTip = "仅针对可解冻的文件进行解冻";
                    this.preThawTableData = thawList;
                    this.thawFileIndex = "批量";
                    this.showThawDialog = true;
                }
            });
        },
        doThawFiles(bucketName, keys) {
            const {bucket, fullPath, regionId, tableData} = this;
            if (typeof this.thawFileIndex === 'number') {
                this.thawLoading.splice(this.thawFileIndex, 1, true)
            } else if (this.thawFileIndex === "批量") {
                for (let i = 0; i < keys.length; i++) {
                    let ind = tableData.findIndex(item => item.key === keys[i].key)
                    this.thawLoading.splice(ind, 1, true)
                }
            }
            this.$postJson("/file/restore", {bucketName, keysAndVersions: keys, regionId})
                .then((res) => {
                    if (res.code === '200') {
                        if (this.thawIndex === '批量' || (typeof this.thawFileIndex === 'number' && tableData[this.thawFileIndex].directory)) {
                            this.$message.success("可解冻文件申请解冻成功");
                        } else {
                            this.$message.success("申请解冻成功");
                        }
                        if (this.thawFileIndex === 'version') {
                            this.$refs.fileHistoryRef.loadVersion();
                        }
                        // 解冻成功后，删除本地缓存
                        for (let i = 0; i < keys.length; i++) {
                            deleteMeta(bucketName + keys[i].key)
                        }
                        // this.loadData(bucket, fullPath, true);
                        this.loadMetaBykeys(keys)
                    } else {
                        this.$message.error(`文件解冻失败，原因为${res.msg},请联系客服人员进行处理。`);
                    }
                })
                .catch((e) => {
                    this.showThawDialog = false;
                    this.$message.error(`文件解冻失败，原因为系统异常,请联系客服人员进行处理。`);
                    this.thawLoading = [false, false, false, false, false, false, false, false, false, false]
                })
                .finally(() => {
                })
        },
        loadMetaBykeys(keys) {
            console.log(keys)
            const {regionId, tableData} = this;
            for (let i = 0; i < keys.length; i++) {
                let ind = tableData.findIndex(item => item.key === keys[i].key)
                const data = {bucketName: tableData[ind].bucketName, key: keys[i].key, regionId};
                this.thawLoading.splice(ind, 1, true)
                this.$postJson("/file/meta", data).then((res) => {
                    this.$set(tableData[ind], "status", res.data.status);
                    // meta信息缓存起来
                    addMeta({id: tableData[ind].bucketName + keys[i].key, status: res.data.status})
                })
                    .finally(() => {
                        this.thawLoading.splice(ind, 1, false)
                        this.$refs.tableRef.clearSelection();
                    })
            }
        },

        downloadFiles() {
            const {selectedRow, regionId} = this;
            if (selectedRow.length <= 0) {
                this.$message.warning("请至少选择一个");
                return;
            }
            const downloadList = [];
            let folders = []
            for (let i = 0; i < selectedRow.length; i++) {
                const row = selectedRow[i];
                if (row.directory) {
                    this.$message.warning("文件夹不支持下载");
                    return;
                }
                if (row.status === "1") {
                    this.$message.warning("所选文件中包括未解冻文件，请先对其解冻");
                    return;
                }
                if (row.status === "2") {
                    this.$message.warning("所选文件中包括未解冻文件，请先对其解冻");
                    return;
                }

                if (row.directory) {
                    folders.push(row)
                } else {
                    downloadList.push(row);
                }

            }
            this.handleFolders(folders)
            const urls = [];
            for (let j = 0; j < downloadList.length; j++) {
                const file = downloadList[j];
				this.$post(`/file/download/count?bucketName=${file.bucketName}&regionId=${regionId}&size=${file.size}`).then(() => {
					this.loadDetail();
				});
				if (this.$isClintUpload()) {
					const url = this.s3.getObjectUrl({
						bucket: file.bucketName,
						key: file.key,
					});
					urls.push({url, displayName: file.displayName});
				} else {
					this.downloadFileFromService(file)
				}

            }
			if (this.$isClintUpload()) {
				this.downloadFilesByUrls(urls);
			}
        },
        handleFolders(list) {
            if (!list || list.length === 0) {
                return
            }
            const {regionId} = this;
            // let parentPath = parents.map(n => n.name).join('/') ? parents.map(n => n.name).join('/')+'/' : ''
            let keys = list.map(i => {
                return i.key
            })
            let data = {
                bucketName: list[0].bucketName,
                keys,
                regionId
            }
            this.$postJson('/file/folder/check', data)
                .then(res => {
                    if (res.code === '200') {
                        if (res.data === 2 || res.data === 3) {
                            this.$message.warning("文件夹中未解冻文件无法下载");
                        }
                        list.forEach(item => this.downloadFolder(item))
                    }
                })
        },
        downloadFile(index, row) {
            const {regionId} = this;
            if (row.directory) {
                // this.downloadFolder(index, row);
                this.handleFolders([row])
                return
            }
            const url = this.s3.getObjectUrl({
                bucket: row.bucketName,
                key: row.key,
                Expires: 24 * 3600,
                versionId: row.versionId
            });

			if (this.$isClintUpload()) {
				this.$post(`/file/download/count?bucketName=${row.bucketName}&regionId=${regionId}&size=${row.size}`).then(() => {
					this.loadDetail();
				});
				this.downloadFilesByUrls([{url, displayName: row.displayName}]);
			} else {
				this.downloadFileFromService(row)
			}
        },

		downloadFileFromService(file) {
			this.$post(`/file/download/count?bucketName=${file.bucketName}&regionId=${this.regionId}&size=${file.size}`).then(() => {
				this.loadDetail();
			});
			window.open(`/api/file/downloadByUrl/${this.bucket}/${file.key}?versionId=${file.versionId || ''}&token=${storage.get('PRIVATE_BLUESTORE_TOKEN')}&regionId=${this.regionId}`, '_blank')
		},

        // file/folder/download
        downloadFolder(row) {
            const {regionId, currentItem} = this;
            this.$post(`/file/download/count?bucketName=${currentItem.name}&regionId=${regionId}`).then(() => {
                this.loadDetail();
            });
            this.$postJson('/file/folder/download', {
                bucketName: row.bucketName,
                key: row.key,
                regionId
            })
                .then(res => {
                    const temp = new Blob([res.data], {type: "application/zip"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(temp, row.key.substr(0, row.key.length - 1) + ".zip");
                    } else {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(temp);
                        link.setAttribute("download", row.key.substr(0, row.key.length - 1) + ".zip");
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                })
                .catch(err => {
                    console.log('err============', err)
                })
        },
        downloadFilesByUrls(urls = []) {
            for (let j = 0; j < urls.length; j++) {
                const item = urls[j];
                // this.downloadFileDo(item.url)
                let url = item.url
                // if (this.currentItem.bucketAcl && this.currentItem.bucketAcl.indexOf('公共') > -1) {
                //     url = url.split('?')[0]
                // }
                window.open(url)
            }
        },
        downloadFileDo(url) {
            var iframe = document.createElement("iframe");
            iframe.style.display = "none"; // 防止影响页面
            iframe.style.height = 0; // 防止影响页面
            iframe.src = url;
            document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
            // 5分钟之后删除
            setTimeout(function () {
                iframe.remove();
            }, 5 * 60 * 1000);
        },
        handleDelete (row) {
            this.delFilesSubmit([row])
        },
        delFiles(selectData) {
            console.log('selectData: ', selectData)
            if (selectData.length == 0) {
                this.$message.error('请至少选择一个文件')
                return
            }

            // let list1 = selectData.filter(item => {
            //     return item.storageClass === 'ARCHIVE' || item.storageClass === 'GLACIER'
            //     // || (item.storageClass === 'INTELLIGENT' && item.meta.lastModified && this.moment(item.meta.lastModified).add(90, 'd')>this.moment()))
            // })
            //
            // if (list1.length > 0) {
            //     this.$message.warning('深度归档类型不支持删除：' + list1[0].displayName)
            //     return;
            // }

            // let list2 = selectData.filter(item => {
            //     return  (item.storageClass === 'INTELLIGENT' && item.meta.lastModified && this.moment(item.meta.lastModified).add(90, 'd')< this.moment())
            // })
            //
            // if (list2.length > 0) {
            //     this.$message.warning('智能存储文件已上传超过90天，不支持删除：'+ list2[0].displayName)
            //     return;
            // }


            this.delFilesSubmit(selectData)
        },
        delFilesSubmit(selectData = []) {

            let data = {
                regionId: this.regionId,
                bucketKeyList: selectData.map(item => {
                    return {
                        bucketName: item.bucketName,
                        key: item.key
                    }
                })
            }
            this.$confirm(`文件删除后无法恢复，确认删除文件吗?`, '删除文件', {
                type: 'warning',
            }).then((res) => {
                this.$postJson('/file/batchDeleteDifBucket', data)
                    .then((res) => {
                        if (res.code === '200') {
                            if (!res.data || res.data.length === 0) {
                                this.$message.success('删除文件成功')
                            } else {
                                this.$alert(res.data, '提示')
                            }
                            this.loadData(this.bucket, this.fullPath, true);
                        }
                    })
            })
        },
        handleDeleteFile(files = []) {
            if (files.length === 0) {
                return
            }
            let name = ''
            if (files.length > 1) {
                name = '所选文件或文件夹'
            } else {
                if (files[0].directory) {
                    name = `文件夹“${files[0].displayName}”`
                } else {
                    name = `文件“${files[0].displayName}”`
                }
            }
            this.$confirm("<b>确定删除" + name + " 吗？</b><br/>"
                + '由于蓝光介质不可擦除，删除后数据仍可通过其它方式访问，仅无法从控制台查看该数据，且该数据已占用容量无法释放。', {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
                dangerouslyUseHTMLString: true
            }).then(() => {
                // /file/hide
                let data = {
                    bucketName: this.bucket,
                    keys: files.map(item => item.key),
                    regionId: this.regionId
                }
                this.$postJson('/file/hide', data)
                    .then(res => {
                        if (res.code === '200') {
                            this.$message.success('操作成功')
                            const {bucket, fullPath} = this
                            this.loadData(this.bucket, this.fullPath, true);
                            this.$refs.fileHistoryRef.hide()
                        }
                    })
            })
        },
        handleVisibleChange(v) {
            this.dropdownVisible = v;
        },
        handleCommand(command) {
            const {selectedRow, regionId} = this;
            if (selectedRow.length <= 0) {
                this.$message.warning("请至少选择一个");
                return;
            }
            const filterRow = selectedRow.filter(i => i.directory)
            if (filterRow.length > 0 && (command === 'download' || command === 'delete' || command === 'addTag')) {
                this.$message.warning("文件夹不支持当前操作");
                return;
            }
            switch (command) {
                case "thaw":
                    this.thawFiles();
                    break;
                case "download":
                    this.downloadFiles();
                    break;
                case "delete":
                    this.delFiles(selectedRow);
                    break;
                case "addTag":
                    this.$refs.addTagRef.show(selectedRow, this.bucket, this.regionId);
                    break;
            }
        },
        handleShowSetup() {
            vm.$emit('show-setup')
        },
        setTips (row) {

        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep.folder-input .el-input__inner {
    padding-right: 78px;
}

::v-deep.bucket-crumb .el-breadcrumb__inner {
    color: #337dff;
}

::v-deep.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #333333 !important;
}

.alert {
    margin: 0px 0px 0px 0;
}

.bucket-info {
    margin: 10px 20px 0px 0;
    padding: 20px 0;
    background: white;
}

.bucket-info .info-item {
    display: flex;
    margin-left: 0px;
    margin-bottom: 10px;
    line-height: 30px;

    .info-value {
        color: rgba(34, 34, 34, 0.6);
    }
}

.vol-tip {
    cursor: pointer;
}

.file {
    margin: 20px;

    background: #fff;
    text-align: left;
    //隐藏dialog header
    ::v-deep.changeConfirm {
        .el-dialog__header {
            //display: none;
            border: none;
            padding: 30px 30px 0;

            .el-dialog__headerbtn {
                top: 30px;
                right: 30px;
            }
        }
    }

    ::v-deep.uploadFile {
        .el-dialog__header {
            border: none;
            padding: 30px 30px 0;

            .el-dialog__headerbtn {
                top: 30px;
                right: 30px;
            }
        }
    }

    .el-table {
        color: #333333;
    }
}


.file-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
    .search-input {
        display: flex;
        flex-wrap: nowrap;
    }
}

.info-label {
    width: 72px;
    margin-right: 20px;
    text-align: left;
    color: #666;
}

.cc-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px -40px 30px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;

    span {
        margin-left: 9px;
    }


}

.cc-body {
    width: 100%;
    margin: 0 auto 18px;
    text-align: justify;
    font-size: 14px;
    color: #333;
    position: relative;

    [class^="el-icon-"],
    [class*=" el-icon-"] {
        font-size: 14px;
        color: #FAAD14;
        position: absolute;
        left: -17px;
        top: 3px;
    }
}

.cc-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px -50px 0 -40px;

    .no-again-tip {
        width: 100px;
        flex-grow: 1;
        flex-shrink: 1;

        ::v-deep.el-checkbox__label {
            font-size: 12px;
            color: #333;
        }
    }

    .el-button {
        width: 78px;
        height: 32px;
        line-height: 32px;
        padding: 0px;
        border-radius: 3px;
        font-size: 12px;
    }
}

.thaw {
    justify-content: center;
}

::v-deep.batchbtns {
    margin-top: 0px;
    width: 87px;
    padding: 0px;
    border-radius: 0px;

    .el-dropdown-menu__item {
        width: 87px;
        height: 34px;
        line-height: 34px;
        padding: 0px;
        font-size: 12px;
        text-align: center;
        color: #333333;
        letter-spacing: 0;

        &:hover {
            background: rgba(51, 125, 255, 0.05);
            color: inherit;
        }

        &.is-disabled {
            background-color: #f5f5f5;
            border-color: #d9d9d9;
            color: #bbbbbb;
            cursor: not-allowed;
        }
    }

    .popper__arrow {
        display: none;
    }
}

.file-upload-btn {
    display: flex;
    align-items: center;
    line-height: initial;
}

.file-upload {
    width: 12px;
    height: 12px;
    margin-right: 6px;
}

.file-container {
    min-height: calc(100% - 136px);
    background: #f4f4f4;
    text-align: left;
    position: relative;
}

.crumb {
    padding: 4px 26px 0;
}
</style>
