<template>
        <el-button
            ref="spanRef"
            size="small"
            type="text"
            style="margin-left: 10px;"
            @click.stop="handleDropdown">
            更多
            <i :class="open ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"/>
        </el-button>
</template>

<script>
import vm from '@/plugin/eventUtil'
export default {
    name: "more-action",
    props: {
        id: {
            type: String,
            default: Math.random() + ''
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            open: true,
        }
    },
    mounted() {
        vm.$on('dropdown-select', (actionId, action) => {
            if (this.id === actionId) {
                this.$emit('select', action)
                this.open = true
            }
        })

        vm.$on('body-click', () => {
            this.open = true
        })
    },
    methods: {
        handleDropdown (e) {
            this.open = !this.open
            let res = this.$refs.spanRef.$el.getBoundingClientRect();
            this.$dropdown.toggle(res.top, res.left, this.id, this.list)
        }
    }
}
</script>

<style scoped>
.more-action {
    cursor: pointer;
}

</style>