<template>
    <el-dialog title="设置读写权限" :visible.sync="visible" :close-on-click-modal="false" @close="handleClose">
        <el-form :model="form" label-width="100px" v-loading="loading2">
            <el-form-item label="文件读写权限">
                <el-radio-group v-model="form.aclType" size="small">
                    <el-radio-button label="PRIVATE">私有</el-radio-button>
                    <el-radio-button label="PUBLIC_READ">公共读</el-radio-button>
                    <el-radio-button label="PUBLIC_READ_WRITE">公共读写</el-radio-button>
                </el-radio-group>
                <div class="tips" v-show="form.aclType === 'PRIVATE'">私有：对文件的所有访问操作需要进行身份验证</div>
                <div class="tips" v-show="form.aclType === 'PUBLIC_READ'" >公共读：可以不通过身份认证直接读取您桶中的数据，安全风险高，为确保数据安全，推荐您选择私有</div>
                <div class="tips" v-show="form.aclType === 'PUBLIC_READ_WRITE'" >公共读写：可以不通过身份认证直接读取您桶中的数据，安全风险高，为确保数据安全，推荐您选择私有</div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取消</el-button>
            <el-button :loading="loading" size="small" @click="handleSubmit" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "file-set-auth",
    props: ['regionId'],
    data () {
        return {
            visible: false,
            form: {// DEFAULT/PRIVATE/PUBLIC_READ/PUBLIC_READ_WRITE
                aclType: 'DEFAULT'
            },
            url: '',
            row: {},
            loading: false,
            loading2: false
        }
    },
    methods: {
        show (row, s3) {
            this.visible = true
            this.row = row
            this.loading2 = true
            this.$postJson('/file/acl/get', {bucketName: row.bucketName, key: row.key, regionId: this.regionId})
            .then(res => {
                if (res.code === '200') {
                    this.form.aclType = res.data
                }
            })
            .finally(() => {
                this.loading2 = false
            })
        },
        handleClose () {
            this.visible = false
        },
        handleSubmit () {
            const {row, form} = this;
            let data = {
                aclType: form.aclType,
                bucketName: row.bucketName,
                key: row.key,
                regionId: this.regionId
            }
            this.loading = true
            this.$postJson('/file/acl/put', data)
            .then(res => {
                if (res.code === '200') {
                    this.$message.success('设置成功')
                    this.visible = false
                    this.$emit('set-auth')
                }
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
.el-form-item {
    text-align: left;
}
.btns {
    > span {
        cursor: pointer;
        padding: 0 2px;
    }
    > span:first-child {
        border-right: 1px solid #125de1;
    }
}

.tips {
    font-size: 12px;
    line-height: 18px;
    color: #999;
    height: 36px;
}
</style>