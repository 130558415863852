<template>
    <el-dialog :visible.sync="visible" custom-class="file-history" :title="title">
        <div class="header">
            <span>历史版本</span>
            <el-tooltip slot="suffix" content="文档的历史版本可下载，恢复；恢复前，请先进行文档解冻" placement="right">
                <span class="vol-tip"></span>
            </el-tooltip>
        </div>
        <div class="list file-history-loading" v-loading="loading">
            <div class="item" v-for="item in tableData">
                <div class="time">{{moment(item.lastModified).format('YYYY-MM-DD HH:mm:ss')}}</div>
                <div class="size">{{item.sizeStr || '-'}}</div>
                <div class="action">
                    <span v-if="item.latest">当前版本</span>
                    <span v-else style="display: flex;">
                        <el-link :underline="false" @click="handleRestore(item)" v-show="item.status == '1'" type="text" size="small">解冻</el-link>
                        <el-link :underline="false" v-show="item.status == '2'" type="text" size="small">解冻中</el-link>
                        <el-link :underline="false" @click="handleDownload(item)" v-show="item.status == '3'" type="text" size="small">下载</el-link>
                        <el-link :underline="false" @click="versionRestore(item)" v-show="item.status == '3' && bucketAuth === 'READ_AND_WRITE'" type="text" size="small" style="margin-left: 10px;">恢复</el-link>
                    </span>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment'
export default {
    name: "file-history",
    props: ['regionId', 'bucketAuth'],
    data() {
        return {
            title: '',
            visible: false,
            tableData: [
            ],
            loading: false,
            obj: {}
        }
    },
    methods: {
        moment,
        handleDelete (row) {
            this.$emit('file-delete', row)
        },
        versionRestore({bucketName, key, versionId, status}) {
            this.$postJson(`/file/version/renew?bucketName=${bucketName}&key=${key}&versionId=${versionId}&regionId=${this.regionId}`)
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('恢复成功')
                        this.loadVersion(this.obj)
                    }
                })
        },
        handleRestore (row) {
            this.$emit('file-restore', row)
        },
        handleDownload (row) {
            this.$emit('file-download', row)
        },
        show(row) {
            this.tableData = []
            this.visible = true
            this.title = row.displayName
            this.obj = row
            this.loadVersion()
        },
        hide () {
            this.visible = false
            this.tableData = []
        },
        loadVersion() {
            const {bucketName, key} = this.obj
            let keys = [key]
            this.loading = true
            this.$postJson('/file/list/version', {bucketName, keys, regionId: this.regionId})
                .then(res => {
                    if (res.code === '200') {
                        this.tableData = res.data[0].versionSummaries
                        for (let j = 0; j < this.tableData.length; j++) {
                            const {bucketName, key, versionId} = this.tableData[j];
                            const data = {bucketName, key, versionId, regionId: this.regionId};
                            this.$postJson("/file/meta", data).then((res) => {
                                this.$set(this.tableData[j], "status", res.data.status);
                                console.log(this.tableData)
                            });
                        }
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 0)
                })
        },
    }
}
</script>

<style scoped lang="scss">


.header{
    height: 52px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 52px;
}



.vol-tip {
    cursor: pointer;
    padding:  10px 15px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAABx0lEQVQ4Ea1SPUvDUBRN+kE7mEFQEJ1c/OjkYgcn3Tp1S9KCSulQQadCRXAquFlxUocKxSqUJt36BwQHoT9BXaSDCCIGmrqIjeekeSGWbvrgcc8999yPl1xJ+uORR/NVVZ2QZbnkOE4adpJxYAumrSjKSa1W6wVzQkFH07QN+B0k9OPxeMo0zXneSCSSQrFP27Y7nsZP8ydgAImn0WhUazQaT1QUCoUobbVa/aLVdX0BGhOwiMK35NwJODYCZ8FkFDyyLKvPq6rqMcWGYTxCpwOe5/N5hZx4wj5GrIvOSFhB7CAUCq0jIQlcBEcrtVqtB3DXvV6vRF8USIfD4ToJ77zCas1m8x7WRnEH9tuLSbFYjNq08CVUf/adAAA/zRiecxmgXQiuSyAmGI27PjrvAnQTicTOWAFIUeADnWbGiO7AHZbL5UEwlslkZvEd3smJAm3gHImRs4op1kY4aTAY5MAxxy9QgXAbUyySFAdd9niFT4tdWAa3BVih706AX2MDU2hms9klBniwgUneoTdMRneD38bLkfxNpMjbxgsIbrADV/iNL+Qx2RxMDneTyWIL4f8uQIIb5i0J//OUp3mD5ZsrojPw/5wfpta4Z/jj2iYAAAAASUVORK5CYII=") no-repeat center center;
    background-size: 15px;
}

.list {
    overflow: auto;
}

.item {
    height: 38px;
    line-height: 38px;
    background: #FAFAFA;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #F2F2F2;
    display: flex;
    flex-direction: row;
    .time {
        text-indent: 16px;
        flex: 1;
    }
    .size {
        flex: 1;
    }
    .action {
        display: flex;
        justify-content: space-around;
        width: 150px;
    }
}
</style>