<template>
	<div class="bucket-home-auth">
		<div class="bucket-home-auth-referer">
			<div class="title">
                <span class="label">
                    防盗链
					<!--     使用说明参照防盗链使用说明       -->
                    <el-tooltip slot="suffix" content="支持设置HTTP Referer白名单实现防止盗链" placement="top">
                        <span class="vol-tip"></span>
                    </el-tooltip>
                </span>
				<span @click="showForm=true"
					  v-if="(accountType === 'main' || bucketAuth === 'READ_AND_WRITE') && !showForm">
                    <i class="icon iconfont iconedit"></i>
                </span>
				<span v-else class="content setup disabled">
                                <i class="icon iconfont iconedit"></i>
                            </span>
			</div>
			<div class="status title" v-show="!showForm">
				<span class="label" style="color: #999;">当前状态</span>
				<span class="content" style="color: #666;">{{ status === 1 ? '开启' : '关闭' }}</span>
			</div>
			<div class="status title" v-show="!showForm && status === 1">
				<span class="label" style="color: #999;">空Referer</span>
				<span class="content" style="color: #666;">{{ form1.nulReferer === 1 ? '允许' : '拒绝' }}</span>
			</div>
			<div class="status title" v-show="!showForm && status === 1">
				<span class="label" style="color: #999;">Referer</span>
				<span class="content" style="color: #666;">{{ form1.referer }}</span>
			</div>
			<el-form v-show="showForm" style="width: 400px;" :model="form1" label-width="80px" label-position="left">
				<el-form-item label="状态">
					<el-switch
						v-model="form1.status"
						active-color="#337DFF"
						inactive-color="#f2f2f2">
					</el-switch>
				</el-form-item>
				<el-form-item label="空Referer" v-if="form1.status">
					<el-radio-group v-model="form1.nulReferer">
						<el-radio :label="1">允许</el-radio>
						<el-radio :label="2">拒绝</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="Referer" v-if="form1.status">
					<el-input v-model.trim="form1.referer" type="textarea" placeholder="请输入域名或IP，支持通配符（*）,域名或IP以逗号分隔"
							  :rows="5"></el-input>
				</el-form-item>
				<div>
					<el-button type="" size="small" @click="handleCancel">取消</el-button>
					<el-button type="primary" size="small" @click="submit">保存</el-button>
				</div>
			</el-form>
		</div>
		<div class="bucket-home-auth-acl" style="margin-top: 20px;">
			<div class="title">
				<span class="label">读写权限</span>
				<span class="content setup" @click="showForm2=true"
					  v-if="(accountType === 'main' || bucketAuth === 'READ_AND_WRITE') && !showForm2">
                    <i class="icon iconfont iconedit"></i>
                </span>
				<span v-else class="content setup disabled">
                                <i class="icon iconfont iconedit"></i>
                            </span>
			</div>
			<div class="status title" v-show="!showForm2">
				<span class="label" style="color: #999;">当前状态</span>
				<!--                   PRIVATE/PUBLIC_READ/PUBLIC_READ_WRITE -->
				<span class="content" style="color: #666;">{{
						{
							PRIVATE: '私有',
							PUBLIC_READ: '公共读',
							PUBLIC_READ_WRITE: '公共读写'
						}[aclType]
					}}</span>
			</div>
			<el-form style="width: 400px;" v-show="showForm2" :model="form2" label-width="80px" label-position="left">
				<el-form-item label="设置权限">
					<el-radio-group v-model="form2.aclType" size="small">
						<el-radio-button label="PRIVATE">私有</el-radio-button>
						<el-radio-button label="PUBLIC_READ">公共读</el-radio-button>
						<el-radio-button label="PUBLIC_READ_WRITE">公共读写</el-radio-button>
					</el-radio-group>
					<div class="tips" v-show="form2.aclType==='PRIVATE'">私有：对文件的所有访问操作需要进行身份验证。</div>
					<div class="tips" v-show="form2.aclType === 'PUBLIC_READ'">
						公共读/公共读写：可以不通过身份认证直接读取您桶中的数据，安全风险高，为确保数据安全，推荐您选择私有。
					</div>
					<div class="tips" v-show="form2.aclType === 'PUBLIC_READ_WRITE'">
						公共读/公共读写：可以不通过身份认证直接读取您桶中的数据，安全风险高，为确保数据安全，推荐您选择私有。
					</div>
				</el-form-item>
				<div>
					<el-button type="" size="small" @click="handleAclCancel">取消</el-button>
					<el-button type="primary" size="small" @click="aclSubmit">保存</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "bucket-home-auth",
	props: {
		bucket: {type: String, default: ''},
		regionId: {
			type: String,
			default: ''
		},
		bucketAuth: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			showForm: false,
			form1: {
				"nulReferer": 1,
				"referer": "",
				"status": false
			},
			status: '',
			loading: null,
			form2: {
				aclType: ''
			},
			aclType: '',
			showForm2: false
		}
	},
	computed: {
		...mapGetters(['accountType'])
	},
	created() {
		this.getStatus();
		this.getAclStatus();
	},
	methods: {
		show() {
			this.handleCancel();
			this.handleAclCancel()
		},
		getStatus() {
			this.$get('/bucket/referer/get?bucketName=' + this.bucket + '&regionId=' + this.regionId)
				.then(res => {
					if (res.code === '200') {
						this.form1 = {...res.data, status: res.data.status === 1}
						this.status = res.data.status
						this.loading && this.loading.close();
					}
				})
				.catch(err => this.loading && this.loading.close())
		},
		getAclStatus() {
			this.$get('/bucket/acl/get?bucketName=' + this.bucket + '&regionId=' + this.regionId)
				.then(res => {
					if (res.code === '200') {
						this.form2.aclType = res.data
						this.aclType = res.data
						this.loading && this.loading.close();
					}
				})
				.catch(err => this.loading && this.loading.close())
		},
		handleCancel() {
			this.showForm = false;
			this.getStatus()
		},
		handleAclCancel() {
			this.showForm2 = false;
			this.getAclStatus()
		},
		submit() {
			const {nulReferer, referer, status, bucketName} = this.form1
			if (status && !referer) {
				this.$message.warning('请输入域名或IP')
				return
			}
			if (status && !/^([0-9a-zA-Z\-]|\.|,|\*)+$/.test(referer)) {
				this.$message.warning('referer格式错误！')
				return
			}
			let data = {bucketName: this.bucket, status: status ? 1 : 2, regionId: this.regionId}
			if (status) {
				data.type = nulReferer
				data.referer = referer
			}

			this.loading = this.$loading({target: '.bucket-home-auth-referer'})
			this.$postJson('/bucket/referer/put', data)
				.then(res => {
					if (res.code === '200') {
						this.$message.success('设置成功！')
						this.handleCancel();
					}
				})
				.finally(() => {
					this.loading.close()
				})
		},
		aclSubmit() {
			const {aclType} = this.form2
			let data = {aclType, bucketName: this.bucket, regionId: this.regionId}
			this.loading = this.$loading({target: '.bucket-home-auth-acl'})
			this.$postJson('/bucket/acl/put', data)
				.then(res => {
					if (res.code === '200') {
						this.$message.success('设置成功！')
						this.handleAclCancel();
					}
				})
				.finally(() => {
					this.loading.close()
				})
		}
	}
}
</script>


<style scoped lang="scss">
.bucket-home-auth {
	background: #fff;
	padding: 20px;
}

.title {
	text-align: left;
	line-height: 35px;
	display: flex;

	> span {
		font-size: 12px;
		color: #031129;
		letter-spacing: 0;
	}

	> span.label {
		width: 68px;
	}

	> span.content {
		font-size: 12px;
		color: #337DFF;
		letter-spacing: 0;
		text-align: center;
		margin-left: 20px;
	}

	span.setup {
		cursor: pointer;
	}

	span.setup.disabled {
		cursor: not-allowed;
		color: #999;
	}
}

.el-form-item {
	text-align: left;
}

.tips {
	font-size: 12px;
	color: #BBBBBB;
	letter-spacing: 0;
	line-height: 20px;
}

.vol-tip {
	cursor: pointer;
	padding: 10px 15px;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAABx0lEQVQ4Ea1SPUvDUBRN+kE7mEFQEJ1c/OjkYgcn3Tp1S9KCSulQQadCRXAquFlxUocKxSqUJt36BwQHoT9BXaSDCCIGmrqIjeekeSGWbvrgcc8999yPl1xJ+uORR/NVVZ2QZbnkOE4adpJxYAumrSjKSa1W6wVzQkFH07QN+B0k9OPxeMo0zXneSCSSQrFP27Y7nsZP8ydgAImn0WhUazQaT1QUCoUobbVa/aLVdX0BGhOwiMK35NwJODYCZ8FkFDyyLKvPq6rqMcWGYTxCpwOe5/N5hZx4wj5GrIvOSFhB7CAUCq0jIQlcBEcrtVqtB3DXvV6vRF8USIfD4ToJ77zCas1m8x7WRnEH9tuLSbFYjNq08CVUf/adAAA/zRiecxmgXQiuSyAmGI27PjrvAnQTicTOWAFIUeADnWbGiO7AHZbL5UEwlslkZvEd3smJAm3gHImRs4op1kY4aTAY5MAxxy9QgXAbUyySFAdd9niFT4tdWAa3BVih706AX2MDU2hms9klBniwgUneoTdMRneD38bLkfxNpMjbxgsIbrADV/iNL+Qx2RxMDneTyWIL4f8uQIIb5i0J//OUp3mD5ZsrojPw/5wfpta4Z/jj2iYAAAAASUVORK5CYII=") no-repeat center center;
	background-size: 15px;
}
</style>
