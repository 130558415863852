<template>
    <el-dialog :title="isEdit?'修改规则':'添加规则'" :visible.sync="visible" append-to-body
               :close-on-click-modal="false" width="700px">

        <el-form ref="lifeCycleRef" :model="form" label-width="100px" v-loading="loading">
            <el-form-item label="规则状态" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio label="Enabled">开启</el-radio>
                    <el-radio label="Disabled">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="规则名称" prop="name" :rules="[{required: true, message: '请输入规则名称'}]">
                <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item label="应用范围" prop="prefix">
                <el-radio-group v-model="scope">
                    <el-radio label="1">整个存储桶</el-radio>
                    <el-radio label="2">指定前缀</el-radio>
                </el-radio-group>
                <el-input v-if="scope==='2'" v-model.trim="form.prefix"/>
            </el-form-item>
            <el-form-item label="生效时间" prop="timeOfEntry">
                <div class="item">
                    <el-radio-group v-model="timeOfEntry" @change="handleTimeChange">
                        <el-radio label="1">按天数</el-radio>
                        <el-radio label="2">按日期</el-radio>
                    </el-radio-group>
                </div>
                <div v-if="timeOfEntry === '1'">
                    <div class="item">
                        <el-checkbox v-model="checkExpirationInDays" :disabled="checkDays"/>
                        文件更新
                        <el-input-number :controls="false" :min="1" style="width: 100px;" v-model="expirationInDays"
                                         :disabled="!checkExpirationInDays" :precision="0"/>
                        天后删除
                    </div>
                    <div class="item">
                        <el-checkbox v-model="checkDays" :disabled="checkExpirationInDays"/>
                        文件更新
                        <el-input-number :controls="false" :min="1" style="width: 100px;" v-model="days"
                                         :disabled="!checkDays" :precision="0"/>
                        天后转储为深度归档存储
                    </div>
                </div>
                <div v-else>
                    <div class="item">
                        <el-checkbox v-model="checkExpirationDate" :disabled="checkDate"/>
                        文件于
                        <el-date-picker
                            style="width: 200px;"
                            v-model="expirationDate"
                            type="date"
                            :disabled="!checkExpirationDate"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                        </el-date-picker>
                        后删除
                    </div>
                    <div class="item">
                        <el-checkbox v-model="checkDate" :disabled="checkExpirationDate"/>
                        文件于
                        <el-date-picker
                            style="width: 200px;"
                            v-model="date"
                            type="date"
                            :disabled="!checkDate"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                        </el-date-picker>
                        后转储为深度归档存储
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取消</el-button>
                <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
            </span>
    </el-dialog>
</template>

<script>
import moment from 'moment'

export default {
    name: "life-cycle-form",
    props: ['totalData'],
    data() {
        return {
            title: '添加规则',
            info: {},
            visible: false,
            form: {
                status: 'Enabled',
                name: '',
                prefix: ''
            },
            loading: false,
            scope: '1',
            timeOfEntry: '1',
            // 过期天数
            checkExpirationInDays: false,
            expirationInDays: 0,
            // 过期日期
            checkExpirationDate: false,
            expirationDate: null,
            // 归档天数
            checkDays: false,
            days: 0,
            // 归档日期
            checkDate: false,
            date: null,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < moment().startOf('day').valueOf();
                },
            }
        }
    },
    computed: {
        isEdit() {
            return this.info && this.info.id
        }
    },
    methods: {
        moment,
        show(info) {
            this.info = info || {}
            this.visible = true
// 先重置，后处理编辑
            this.form.status = 'Enabled'
            this.form.name = ''
            this.form.prefix = ''
            this.scope = '1'
            this.timeOfEntry = '1'
            this.checkExpirationInDays = false
            this.expirationInDays = 0
            this.checkExpirationDate = false
            this.expirationDate = null
            this.checkDays = false
            this.days = 0
            this.checkDate = false
            this.date = null
            // 编辑规则回显
            if (this.info.id) {
                this.form.name = info.name
                this.form.status = info.status
                this.form.prefix = info.prefix
                if (info.prefix) {
                    this.scope = '2'
                } else {
                    this.scope = '1'
                }
                // expirationDate   expirationInDays   transitionDate   transitionDays
                if (info.expirationDate) {
                    this.timeOfEntry = '2'
                    this.expirationDate = info.expirationDate
                    this.checkExpirationDate = true
                } else if (info.expirationInDays && info.expirationInDays !== -1) {
                    this.expirationInDays = info.expirationInDays
                    this.checkExpirationInDays = true
                } else if (info.transitionDate) {
                    this.timeOfEntry = '2'
                    this.date = info.transitionDate
                    this.checkDate = true
                } else if (info.transitionDays && info.transitionDays !== -1) {
                    this.days = info.transitionDays
                    this.checkDays = true
                }
            }
        },
        handleCancel() {
            this.visible = false
        },
        handlePrefix() {
            // form.prefix
            const {totalData, form, isEdit, info} = this
            if (!totalData || totalData.length === 0) {
                return false
            }
            let res = totalData.filter(item => {
                if (isEdit && item.id === info.id) {
                    return false
                }
                return item.prefix.indexOf(form.prefix) > -1 || form.prefix.indexOf(item.prefix) > -1
            })
            return res.length > 0
        },
        handleSubmit() {
            // 针对整个Bucket和指定前缀的规则不能同时生效，且不能存在包含关系。
            this.$refs.lifeCycleRef.validate((valid) => {
                if (valid) {
                    const {
                        form,
                        info,
                        scope,
                        timeOfEntry,
                        checkExpirationInDays,
                        expirationInDays,
                        checkDays,
                        days,
                        checkExpirationDate,
                        expirationDate,
                        checkDate,
                        date
                    } = this
                    let data = {
                        ...form,
                        bucketName: info.bucket,
                        storageClass: 'GLACIER',
                        regionId: info.regionId
                    }
                    if (scope === '2') { // 应用范围
                        if (!form.prefix) {
                            this.$message.warning('请填写指定前缀')
                            return
                        }
                        if (this.handlePrefix()) {
                            this.$message.warning('针对整个桶和指定前缀的规则不能同时生效，且不能存在包含关系。')
                            return;
                        }
                    } else {
                        data.prefix = ''
                    }
                    if (timeOfEntry === '1') {
                        if (checkExpirationInDays && expirationInDays > 0) {
                            data.expirationInDays = expirationInDays
                        } else if (checkDays && days > 0) {
                            data.transitionDays = days
                        } else {
                            this.$message.warning('请填写天数')
                            return;
                        }
                    } else if (timeOfEntry === '2') {
                        if (checkExpirationDate && expirationDate) {
                            data.expirationDate = this.moment(expirationDate).format('YYYY-MM-DD')
                        } else if (checkDate && date) {
                            data.transitionDate = this.moment(date).format('YYYY-MM-DD')
                        }
                    }

                    let url = '/bucket/putLifeCycle'
                    if (this.isEdit) {
                        data.ruleId = info.id
                    }
                    this.loading = true
                    this.$postJson(url, data)
                        .then(res => {
                            if (res.code === '200') {
                                this.visible = false
                                this.$emit('complete')
                            }
                        })
                    .finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        // 生效时间类型切换
        handleTimeChange() {
            if (this.isEdit) {

            } else {

            }
        },
        disabledDateFunction(date) {
            return moment(date) >= moment()
        }
    }
}
</script>

<style scoped>

</style>