<template>
    <div class="imgSeting_content">
        <div id="imgStyleList" v-if="!createImgStyleShow">
            <div class="btnBox">
                <el-button size="small" @click="createImgStyle()">创建样式</el-button>
                <el-button size="small" @click="delImgStyle()" type="danger" :disabled="selectedData.length == 0">删除样式</el-button>
            </div>
            <div class="tableBox">
                <el-table :data="tableData" @selection-change="handleSelectionChange" :row-key="(row) => row.bid"
                          ref="table">
                    <el-table-column type="selection" width="45" align="center"></el-table-column>
                    <el-table-column prop="styleName" label="样式名称" :show-overflow-tooltip="true"
                                     width="300"></el-table-column>
                    <el-table-column prop="style" label="样式参数" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作" align="center" width="100">
                        <template slot-scope="scope">
                            <div class="file-table-action-list">
                            <el-button type="text"  @click="showImg(scope.row)">预览</el-button>
                            <el-button type="text"  @click="changeStyle(scope.row)">编辑</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <div>共{{ total }}条，已经选中{{ selectedData.length }}条</div>
                    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
                                   layout="prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>
        </div>
        <div id="addImgStyle" v-if="createImgStyleShow">
            <div class="addStyle">
                <div class="add_left">
                    <div class="addTitle">创建样式</div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="pwd-ruleForm"
                             size="small">
                        <el-form-item label="样式名称" prop="styleName" ref="addimg">
                            <el-input :disabled="!!changeImgTag" type="text" v-model="ruleForm.styleName" minlength="3"
                                      maxlength="63" autocomplete="off"></el-input>
                            <div class="pwd_tips">长度为3-63个字符，仅支持大小写英文字母、数字、中划线“-”，设置后不可修改</div>
                        </el-form-item>
                        <el-tabs v-model="activeName" tab-position="right">
                            <el-tab-pane label="缩放设置" name="first">
                                <div class="style_item">
                                    <el-form-item label="缩放类型" prop="sf_type">
                                        <el-select v-model="ruleForm.sf_type" placeholder="请选择">
                                            <el-option label="保持原尺寸不变" value="0"></el-option>
                                            <el-option label="等比例缩放" value="1"></el-option>
                                            <el-option label="固定宽度和高度" value="2"></el-option>
                                            <el-option label="等比缩放，宽度固定，高度自适应" value="3"></el-option>
                                            <el-option label="等比缩放，高度固定，宽度自适应" value="4"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sf_type == 1">
                                    <el-form-item label="缩放比例" prop="sf_p_num">
                                        <el-input-number v-model="ruleForm.sf_p_num" :min="0"
                                                         :max="1000"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sf_type == 2 || ruleForm.sf_type == 3">
                                    <el-form-item label="宽度(px)" prop="sf_w_num">
                                        <el-input-number v-model="ruleForm.sf_w_num" :min="1"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sf_type == 2 || ruleForm.sf_type == 4">
                                    <el-form-item label="高度(px)" prop="sf_h_num">
                                        <el-input-number v-model="ruleForm.sf_h_num" :min="1"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item"
                                     v-if="ruleForm.sf_type == 2 || ruleForm.sf_type == 3 || ruleForm.sf_type == 4">
                                    <el-form-item label="缩放限制" prop="sf_b_num">
                                        <el-select v-model="ruleForm.sf_b_num" placeholder="请选择">
                                            <el-option label="不限制" value="0"></el-option>
                                            <el-option label="限制放大" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="水印设置" name="second">
                                <div class="style_item">
                                    <el-form-item label="水印类型" prop="sf_p_num">
                                        <el-select v-model="ruleForm.sy_type" placeholder="请选择">
                                            <el-option label="不使用水印" value="0"></el-option>
                                            <el-option label="图片水印" value="1"></el-option>
                                            <el-option label="文字水印" value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item address_img" v-if="ruleForm.sy_type == 1">
                                    <el-form-item label="水印图片路径" prop="sy_address">
                                        <el-input type="text" v-model="ruleForm.sy_address" maxlength="64"
                                                  autocomplete="off"></el-input>
                                        <div class="pwd_tips">请输入当前桶中对象路径，例：aa/b.jpg 或 ccc.jpg</div>
                                    </el-form-item>
                                </div>
                                <div class="style_item address_img" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="文字内容" prop="sy_text">
                                        <el-input type="text" v-model="ruleForm.sy_text" maxlength="64"
                                                  autocomplete="off"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="字体" prop="sy_text_font">
                                        <el-select v-model="ruleForm.sy_text_font" placeholder="请选择">
                                            <el-option v-for="item in options" :key="item" :label="item"
                                                       :value="item"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="字号" prop="sy_text_size">
                                        <el-select v-model="ruleForm.sy_text_size" placeholder="请选择">
                                            <el-option label="10" value="10"></el-option>
                                            <el-option label="20" value="20"></el-option>
                                            <el-option label="30" value="30"></el-option>
                                            <el-option label="50" value="50"></el-option>
                                            <el-option label="80" value="80"></el-option>
                                            <el-option label="100" value="100"></el-option>
                                            <el-option label="120" value="120"></el-option>
                                            <el-option label="140" value="140"></el-option>
                                            <el-option label="160" value="160"></el-option>
                                            <el-option label="180" value="180"></el-option>
                                            <el-option label="200" value="200"></el-option>
                                            <el-option label="300" value="300"></el-option>
                                            <el-option label="500" value="500"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="颜色" prop="sy_text_color">
                                        <el-color-picker v-model="ruleForm.sy_text_color"></el-color-picker>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="文字旋转" prop="sy_rotate">
                                        <el-input-number v-model="ruleForm.sy_rotate" :min="0"
                                                         :max="360"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2">
                                    <el-form-item label="文字透明度(百分比)" prop="sy_shadow">
                                        <el-input-number v-model="ruleForm.sy_shadow" :min="0"
                                                         :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 1">
                                    <el-form-item label="透明度(百分比)" prop="sy_num">
                                        <el-input-number v-model="ruleForm.sy_num" :min="0"
                                                         :max="100"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.sy_type == 2 || ruleForm.sy_type == 1">
                                    <el-form-item label="水印位置" prop="sy_seat">
                                        <el-radio-group v-model="ruleForm.sy_seat" size="medium">
                                            <div class="sy_seat">
                                                <el-radio-button label="nw">左上</el-radio-button>
                                                <el-radio-button label="north">中上</el-radio-button>
                                                <el-radio-button label="ne">右上</el-radio-button>
                                            </div>
                                            <div class="sy_seat">
                                                <el-radio-button label="west">左中</el-radio-button>
                                                <el-radio-button label="center">中部</el-radio-button>
                                                <el-radio-button label="east">右中</el-radio-button>
                                            </div>
                                            <div class="sy_seat">
                                                <el-radio-button label="sw">左下</el-radio-button>
                                                <el-radio-button label="south">中下</el-radio-button>
                                                <el-radio-button label="se">右下</el-radio-button>
                                            </div>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="剪裁设置" name="third">
                                <div class="style_item">
                                    <el-form-item label="裁剪方式" prop="cj_type">
                                        <el-select v-model="ruleForm.cj_type" placeholder="请选择">
                                            <el-option label="保持原尺寸不变" value="0"></el-option>
                                            <el-option label="自定义裁剪" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.cj_type == 1">
                                    <el-form-item label="裁剪位置" prop="cj_seat">
                                        <el-radio-group v-model="ruleForm.cj_seat" size="medium">
                                            <div class="sy_seat">
                                                <el-radio-button label="nw">左上</el-radio-button>
                                                <el-radio-button label="north">中上</el-radio-button>
                                                <el-radio-button label="ne">右上</el-radio-button>
                                            </div>
                                            <div class="sy_seat">
                                                <el-radio-button label="west">左中</el-radio-button>
                                                <el-radio-button label="center">中部</el-radio-button>
                                                <el-radio-button label="east">右中</el-radio-button>
                                            </div>
                                            <div class="sy_seat">
                                                <el-radio-button label="sw">左下</el-radio-button>
                                                <el-radio-button label="south">中下</el-radio-button>
                                                <el-radio-button label="se">右下</el-radio-button>
                                            </div>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.cj_type == 1">
                                    <el-form-item label="剪裁宽度(px)" prop="w">
                                        <el-input-number v-model="ruleForm.crop.w" :min="0"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.cj_type == 1">
                                    <el-form-item label="剪裁高度(px)" prop="h">
                                        <el-input-number v-model="ruleForm.crop.h" :min="0"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.cj_type == 1">
                                    <el-form-item label="剪裁起点横坐标(px)" prop="x">
                                        <el-input-number v-model="ruleForm.crop.x" :min="0"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.cj_type == 1">
                                    <el-form-item label="剪裁起点纵坐标(px)" prop="y">
                                        <el-input-number v-model="ruleForm.crop.y" :min="0"
                                                         :max="4096"></el-input-number>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="图片旋转" name="fourth">
                                <div class="style_item">
                                    <el-form-item label="旋转类型" prop="xz_type">
                                        <el-select v-model="ruleForm.xz_type" placeholder="请选择">
                                            <el-option label="不旋转" value="0"></el-option>
                                            <el-option label="自定义" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="style_item" v-if="ruleForm.xz_type == 1">
                                    <el-form-item label="旋转角度" prop="value">
                                        <el-input-number v-model="ruleForm.rotate.value" :min="0"
                                                         :max="360"></el-input-number>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="格式转换" name="five">
                                <div class="style_item">
                                    <el-form-item label="输出格式" prop="zh_type">
                                        <el-select v-model="ruleForm.zh_type" placeholder="请选择">
                                            <el-option label="原图格式" value="0"></el-option>
                                            <el-option label="jpg" value="jpg"></el-option>
                                            <el-option label="png" value="png"></el-option>
                                            <el-option label="bmp" value="bmp"></el-option>
                                            <el-option label="jpeg" value="jpeg"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                        <el-form-item label="" prop="sf_b_num">
                            <el-button @click="close()">取 消</el-button>
                            <el-button type="primary" @click="addImgStyle(1)">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="add_right">
                    <div class="add_right_top">
                        <p class="add_right_top_title">预览</p>
                        <el-button @click="addImgStyle(0)">刷新</el-button>
                    </div>
                    <div class="imgShow">
                        <img :src="imgSrc" alt="default"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {get} from '@/utils/storage'
import {validImgSetName} from '@/utils/validators'

export default {
    name: 'imageSetting',
    props: ['bucket'],
    data() {
        var bucketName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('图片样式名称不能为空'))
            } else if (!validImgSetName(value)) {
                callback(new Error('图片样式名称格式不正确'))
            } else {
                callback()
            }
        }
        var imgaddress = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('输入内容不能为空'))
            } else {
                callback()
            }
        }
        return {
            activeName: 'first',
            changeImgTag: '',
            createImgStyleShow: false,
            page: 1,
            total: 0,
            pageSize: 10,
            tableData: [],
            selectedData: [],
            activeNames: ['1'],
            ruleForm: {
                styleName: '',

                sf_type: '0',
                sf_p_num: '100',
                sf_w_num: '100',
                sf_h_num: '100',
                sf_b_num: '0',

                sy_type: '0',
                sy_address: '',
                sy_text: '',
                sy_text_font: '',
                sy_text_size: '20',
                sy_text_color: '#409EFF',
                sy_rotate: 0,
                sy_shadow: 100,
                sy_num: '100',
                sy_seat: 'nw',

                cj_type: '0',
                crop: {
                    g: 'nw',
                    h: '0',
                    w: '0',
                    x: '0',
                    y: '0',
                },

                xz_type: '0',
                rotate: {
                    value: '0',
                },

                zh_type: '0',
            },
            rules: {
                styleName: [{validator: bucketName, trigger: 'blur', required: true}],
                sy_address: [{validator: imgaddress, trigger: 'blur', required: true}],
                sy_text: [{validator: imgaddress, trigger: 'blur', required: true}],
                sy_text_font: [{message: '请选择字体', trigger: 'blur', required: true}],
            },
            imgSrc: require('@/assets/images/default.png'),
            options: [],
        }
    },
    mounted() {
    },
    computed: {
    },
    created() {
        this.getImgStyleList()
        this.FontList()
    },
    methods: {
        // ...mapActions('bucketManage', ['getImgList', 'createImg', 'imgShow', 'delImg', 'changeImg', 'getFontList']),
        getImgList () {},
        createImg (data) {
            let imageOperateVo = {
                ...data,
            }
            typeof imageOperateVo.token !== 'undefined' && delete imageOperateVo.token
            typeof imageOperateVo.type !== 'undefined' && delete imageOperateVo.type
            typeof imageOperateVo.bucketName !== 'undefined' && delete imageOperateVo.bucketName
            typeof imageOperateVo.styleName !== 'undefined' && delete imageOperateVo.styleName

            return this.$postJson('/imageStyles/createOrUpdate', {
                bucketName: this.bucket,
                styleName: data.styleName,
                imageOperateVo,
                bid: imageOperateVo.bid
            })
        },
        previewImg (data) {
            let imageOperateVo = {
                ...data,
            }
            typeof imageOperateVo.token !== 'undefined' && delete imageOperateVo.token
            typeof imageOperateVo.type !== 'undefined' && delete imageOperateVo.type
            typeof imageOperateVo.bucketName !== 'undefined' && delete imageOperateVo.bucketName
            typeof imageOperateVo.styleName !== 'undefined' && delete imageOperateVo.styleName

            this.$postJson('/imageStyles/preview', {
                bucketName: this.bucket,
                imageOperateVo
            })
                .then(res => {
                    if (res.code === '200') {
                        this.imgSrc = res.data.data
                    }
                })
        },
        imgShow (data) {
            return this.$postJson('/imageStyles/preview', data)
        },
        delImg () {},
        changeImg (item) {
            return this.$get(`/imageStyles/get/${item.imageStyleBid}`)
        },
        getFontList () {
            this.$get('/fontsType/list')
            .then(res => {
                if (res.code === '200') {
                    this.options = res.data
                }
            })
        },
        createImgStyle() {
            this.createImgStyleShow = true
            this.$nextTick(() => {
                this.changeImgTag = ''
                ;(this.imgSrc = require('@/assets/images/default.png')), (this.ruleForm.styleName = '')
                this.$refs['ruleForm'].resetFields();
            })
        },
        // /api/imageStyles/preview
        addImgStyle(val) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const data = {
                        bucketName: get('bucketName'),
                        styleName: this.ruleForm.styleName,
                        type: val,
                    }
                    if (this.changeImgTag && val !== 0) {
                        data.bid = this.changeImgTag
                    }
                    //缩放
                    const resize = {}
                    if (this.ruleForm.sf_type == 1) {
                        resize.p = this.ruleForm.sf_p_num.toString()
                        resize.m = 'lfit'
                        data.resize = resize
                    } else if (this.ruleForm.sf_type == 2) {
                        resize.h = this.ruleForm.sf_h_num.toString()
                        resize.limit = this.ruleForm.sf_b_num
                        resize.m = 'fixed'
                        resize.w = this.ruleForm.sf_w_num.toString()
                        data.resize = resize
                    } else if (this.ruleForm.sf_type == 3) {
                        resize.w = this.ruleForm.sf_w_num.toString()
                        resize.limit = this.ruleForm.sf_b_num
                        resize.m = 'mfit'
                        data.resize = resize
                    } else if (this.ruleForm.sf_type == 4) {
                        resize.limit = this.ruleForm.sf_b_num
                        resize.m = 'mfit'
                        resize.h = this.ruleForm.sf_h_num.toString()
                        data.resize = resize
                    }
                    //水印
                    const waterMark = {}
                    if (this.ruleForm.sy_type == 1) {
                        waterMark.g = this.ruleForm.sy_seat
                        waterMark.imageName = this.ruleForm.sy_address
                        waterMark.t = this.ruleForm.sy_num.toString()
                        data.waterMark = waterMark
                    } else if (this.ruleForm.sy_type == 2) {
                        const textData = {}
                        waterMark.g = this.ruleForm.sy_seat
                        textData.color = this.ruleForm.sy_text_color
                        textData.size = this.ruleForm.sy_text_size
                        textData.type = this.ruleForm.sy_text_font
                        textData.rotate = this.ruleForm.sy_rotate.toString()
                        textData.shadow = this.ruleForm.sy_shadow.toString()
                        textData.text = this.ruleForm.sy_text
                        waterMark.textWaterMark = textData
                        data.waterMark = waterMark
                    }
                    //裁剪
                    const crop = {}
                    if (this.ruleForm.cj_type != 0) {
                        crop.g = this.ruleForm.crop.g
                        crop.w = this.ruleForm.crop.w.toString()
                        crop.h = this.ruleForm.crop.h.toString()
                        crop.x = this.ruleForm.crop.x.toString()
                        crop.y = this.ruleForm.crop.y.toString()
                        data.crop = crop
                    }
                    //旋转
                    const rotate = {}
                    if (this.ruleForm.xz_type != 0) {
                        rotate.value = this.ruleForm.rotate.value.toString()
                        data.rotate = rotate
                    }
                    //格式转换
                    const format = {}
                    if (this.ruleForm.zh_type != 0) {
                        format.value = this.ruleForm.zh_type
                        data.format = format
                    }
                    if (val === 1) {
                        this.createImg(data).then((res) => {
                            this.$message.success('操作成功')
                            this.createImgStyleShow = false
                            this.$refs['ruleForm'].resetFields()
                            this.getImgStyleList()
                        })
                    } else if (val === 0) {
                        this.previewImg(data)
                    }

                }
            })
        },
        getImgStyleList() {
            const data = {
                bucketName: this.bucket,
                pageSize: this.pageSize,
                pageNum: this.page,
            }
            this.$postJson('/imageStyles/pagelist', data)
            .then(res => {
                if (res.code === '200') {
                    this.total = res.data.total
                    this.tableData = res.data.records
                }
            })
        },
        delImgStyle() {
            this.$confirm('<p>确认删除这些图片样式吗？</p>', '删除图片样式', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true,
            }).then(() => {
                // this.delImg({bucketName: get('bucketName'), styleName: this.selectedData.join('|')})
                    this.$postJson('/imageStyles/delete', this.selectedData)
                    .then((res) => {
                    this.$message.success('删除成功')
                    this.page = 1
                    this.$refs.table.clearSelection()
                    this.getImgStyleList()
                })
            })
        },
        showImg(val) {
            const data = {
                bucketName: val.bucketName,
                imageStyleBid: val.bid,
            }
            this.imgShow(data).then((res) => {
                if (res.code === '200') {
                    var newWin = window.open('')
                    newWin.document.body.innerHTML = '<img src="' + res.data.data + '"/>'
                    this.imgSrc = res.data
                }

            })
        },
        changeStyle(val) {
            // this.$nextTick(()=>{
            this.changeImgTag = val.bid
            this.ruleForm.styleName = val.styleName
            this.createImgStyleShow = true
            const data = {
                bucketName: this.bucket,
                imageStyleBid: val.bid,
            }
            this.imgShow(data).then((res) => {
                if (res.code === '200') {
                    this.imgSrc = res.data.data
                }
            })
            this.changeImg(data).then((res) => {
                if (res.code !== '200') {
                    return
                }
                const result = {...res.data, ...res.data.imageOperateVo}

                if (result.crop) {// 裁剪
                    this.ruleForm.cj_type = '1'
                    this.ruleForm.crop = result.crop
                } else {
                    this.ruleForm.cj_type = '0'
                }
                if (result.rotate) { // 旋转
                    this.ruleForm.xz_type = '1'
                    this.ruleForm.rotate = result.rotate
                } else {
                    this.ruleForm.xz_type = '0'
                }
                if (result.waterMark) { // 水印
                    if (result.waterMark.textWaterMark) {
                        this.ruleForm.sy_type = '2'
                        this.ruleForm.sy_text = result.waterMark.textWaterMark.text
                        this.ruleForm.sy_text_font = result.waterMark.textWaterMark.type
                        this.ruleForm.sy_text_size = result.waterMark.textWaterMark.size
                        this.ruleForm.sy_rotate = result.waterMark.textWaterMark.rotate
                        this.ruleForm.sy_text_color = result.waterMark.textWaterMark.color
                        this.ruleForm.sy_shadow = result.waterMark.textWaterMark.shadow
                        this.ruleForm.sy_seat = result.waterMark.g
                    } else {
                        this.ruleForm.sy_type = '1'
                        this.ruleForm.sy_address = result.waterMark.imageName
                        this.ruleForm.sy_num = result.waterMark.t
                        this.ruleForm.sy_seat = result.waterMark.g
                    }
                } else {
                    this.ruleForm.sy_type = '0'
                }

                if (result.resize) { //  裁剪
                    if (result.resize.m == 'lfit') {
                        this.ruleForm.sf_type = '1'
                        this.ruleForm.sf_p_num = result.resize.p
                    } else if (result.resize.m == 'fixed') {
                        this.ruleForm.sf_type = '2'
                        this.ruleForm.sf_h_num = result.resize.h
                        this.ruleForm.sf_b_num = result.resize.limit
                        this.ruleForm.sf_w_num = result.resize.w
                    } else if (result.resize.m == 'mfit') {
                        if (result.resize.w) {
                            this.ruleForm.sf_type = '3'
                            this.ruleForm.sf_b_num = result.resize.limit
                            this.ruleForm.sf_w_num = result.resize.w
                        } else {
                            this.ruleForm.sf_type = '4'
                            this.ruleForm.sf_h_num = result.resize.h
                            this.ruleForm.sf_b_num = result.resize.limit
                        }
                    }
                } else {
                    this.ruleForm.sf_type = '0'
                }

                if (result.format && result.format.value) {
                    this.ruleForm.zh_type = result.format.value
                }
                // })
            })
        },
        showImgStyle() {
        },
        FontList() {
            this.getFontList()
        },
        close() {
            this.$refs['ruleForm'].resetFields()
            this.createImgStyleShow = false
            this.ruleForm.styleName = ''
            this.changeImgTag = ''
            this.imgSrc = require('@/assets/images/default.png')
        },
        handleSelectionChange(val) {
            this.selectedData = val.map((item) => item.bid)
        },
        handleCurrentChange(val) {
            this.page = val
            this.getImgStyleList()
        },
    },
}
</script>
<style lang="scss" scoped>
.imgSeting_content {
    width: 100%;
    padding: 20px;
}

.btnBox {
    margin-top: 10px;
}

.addTitle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #393a42;
    line-height: 40px;
}

.addStyle {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    background: white;
}

.add_left_bottom {
    margin-top: 10px;
}

.add_left {
    padding: 10px 20px 0 20px;
    width: 48%;
}

.pwd-ruleForm {
    margin-top: 10px;
}

.add_right {
    width: 48%;
    // height: 20rem;
    padding: 10px;
}

.add_right_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add_right_top_title {
    font-weight: 700;
    text-align: left;
    font-size: 16px;
}

.imgShow {
    width: 100%;
    height: 330px;
    overflow: auto;
    margin-top: 20px;
}

.pwd_tips {
    text-align: left;
    color: #656598;
    line-height: 18px !important;
    font-size: 12px;
}
</style>
<style lang="scss">
.add_left {
    .el-collapse-item__header {
        height: 1.8rem !important;
        padding: 0 10px;
        font-weight: 700;
        text-align: left;
        color: #393a42;
        background: #cccccc !important;
    }

    .style_item {
        .el-select {
            width: 100%;
        }

        .el-form-item {
            margin-bottom: 10px;
            margin-top: 10px;

            .el-form-item__content {
                line-height: normal;
            }

            .el-form-item__label {
                background-color: white;

                //width: 8.5rem !important;
                // line-height: normal;
            }
        }
    }

    .address_img {
        .el-form-item {
            margin-bottom: 22px;
        }
    }

    .el-collapse {
        // height: 25rem;
        // overflow-y: auto;
    }

    .el-collapse-item {
        margin-bottom: 10px;
    }

    .el-collapse-item__content {
        padding-bottom: 0;
    }

    .el-collapse-item__wrap {
        max-height: 200px;
        overflow-y: auto;
    }

    .el-input-number {
        width: 100%;
    }
}
</style>