<template>
    <div class="life-cycle">
        <div class="file-page">
            <div class="file-opts">
                <div>
                    <OptButton size="small" type="primary" @click="handleAdd">添加规则</OptButton>
                </div>
                <div style="display: flex;">
                </div>
            </div>

            <el-table
                :data="tableData"
                v-loading="loadingData"
                style="width: 100%">
                <el-table-column prop="key" label="序号" width="80" show-overflow-tooltip>
                    <template slot-scope="{ row, $index }">{{ $index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="name" label="规则名称" ></el-table-column>
                <el-table-column prop="prefix" label="应用范围" >
                    <template slot-scope="{ row }">
                        {{ row.prefix || '整个存储桶' }}
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="规则内容" ></el-table-column>
                <el-table-column prop="status" label="状态" width="80">
                    <template slot-scope="{ row }">
                        {{ row.status === 'Enabled' ? '开启' : '关闭'}}
                    </template>
                </el-table-column>
                <el-table-column prop="key" label="操作" width="120">
                    <template slot-scope="{$index, row}">
                        <div class="file-table-action-list">
                            <el-button type="text" @click="handleEditItem(row)" style="margin-left: -6px;">编辑
                            </el-button>
                            <el-button type="text" @click="handleDeleteItem(row)" style="margin-left: -6px;">删除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <Pager

                v-show="tableData && tableData.length > 0"
                class="bs-pager" v-model="pager" :sizes="[5, 10, 20]" @currentChange="handleCurrentChange"
                @sizeChange="handleSizeChange"></Pager>
        </div>
        <LifeCycleForm ref="lifeCycleFormRef" @complete="loadData" :totalData="totalData"/>

    </div>
</template>

<script>
import LifeCycleForm from "./components/LifeCycleForm";
import task from "@/views/offline/Task";

export default {
    name: "life-cycle",
    components: { LifeCycleForm },
    props: ['bucket', 'regionId'],
    data () {
        return {
            totalData: [],
            tableData: [],
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            loadingData: false,
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loadingData = true
            this.$get('/bucket/getLifeCycle?bucketName=' + this.bucket + '&regionId=' + this.regionId)
            .then(res => {
                if (res.code === '200') {
                    this.totalData = res.data
                    this.pager.total = res.data.length
                    this.setTableList()
                }
            })
            .finally(() => {
                this.loadingData = false
            })
        },
        setTableList () {
            const {totalData, pager} = this
            this.tableData = totalData.slice((pager.current - 1) * pager.size, pager.current * pager.size)
        },
        handleAdd() {
            const {bucket, regionId, totalData} = this
            if (totalData && totalData.length > 0 && (!totalData[0].prefix)) {
                this.$message.warning('已存在针对整个桶的规则，不能创建新的规则')
                return
            }
            this.$refs.lifeCycleFormRef.show({bucket, regionId})
        },
        handleSizeChange(size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.setTableList();
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.setTableList();
        },
        handleDeleteItem (row) {
            this.$confirm("确定删除吗?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                showClose: false,
            }).then(() => {
                this.$postJson('/bucket/deleteLifeCycle', {bucketName: this.bucket, regionId: this.regionId, ruleId: row.id})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('已删除')
                        this.loadData()
                    }
                })
            })
        },
        handleEditItem (row) {
            const {bucket, regionId} = this
            this.$refs.lifeCycleFormRef.show({...row, bucket, regionId})
        }
    }
}
</script>

<style scoped lang="scss">
.life-cycle {
    padding: 20px;
}
.file-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

</style>